import pythonAxios from '../services/axiosConfigPython';

const apiCallWrapper =
  apiCall =>
  async (...args) => {
    try {
      const response = await apiCall(...args);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error(
          `Error: ${error.response.status} - ${error.response.data}`,
        );
        throw new Error(
          error.response.data.detail || 'An error occurred while fetching data',
        );
      } else {
        console.error(`Error: ${error.message}`);
        throw new Error('An error occurred while fetching data');
      }
    }
  };

export const updateUserProfile = async (identifier, data) => {
  const response = await pythonAxios.put(`/users/profile/${identifier}`, data);
  return response.data;
};

export const fetchKPIData = async (
  projectId,
  kpiId,
  aggregation,
  formatted = false,
) => {
  const response = await pythonAxios.get(
    `/kpis/projects/${projectId}/kpis/${kpiId}`,
    {
      params: { aggregation, formatted },
    },
  );
  return response.data.data;
};

// User Portfolio fetchers
export const fetchAggregatedKPIs = async (
  userId,
  kpiIds,
  aggregation = 'annual',
) => {
  const response = await pythonAxios.get(
    `/users/${userId}/portfolio_kpis_aggregated`,
    {
      params: { kpi_ids: kpiIds.join(','), aggregation },
    },
  );
  return response.data;
};

export const fetchUserPortfolioKPIs = async (
  userId,
  selectedCompanyIds,
  aggregation = 'annual',
) => {
  const response = await pythonAxios.get(`/users/${userId}/portfolio_kpis`, {
    params: {
      aggregation,
      company_ids: selectedCompanyIds.join(','),
    },
  });
  return response.data;
};

export const fetchUserPortfolioHierarchy = async userId => {
  try {
    const response = await pythonAxios.get(
      `/users/${userId}/portfolio_hierarchy`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching user portfolio hierarchy:', error);
    throw error;
  }
};

export const fetchUserFinancialModelData = async userId => {
  const response = await pythonAxios.get(
    `/users/${userId}/financial_model_data`,
  );
  return response.data;
};

// Account fetchers
export const fetchAccounts = async (skip = 0, limit = 50) => {
  const response = await pythonAxios.get(
    `/accounts/?skip=${skip}&limit=${limit}`,
  );
  return response.data;
};

export const createAccount = async accountData => {
  const response = await pythonAxios.post('/accounts/', accountData);
  return response.data;
};

export const updateAccount = async (accountName, accountData) => {
  const response = await pythonAxios.put(
    `/accounts/name/${accountName}`,
    accountData,
  );
  return response.data;
};

export const deleteAccount = async accountId => {
  const response = await pythonAxios.delete(`/accounts/${accountId}`);
  return response.data;
};

// Project fetchers
export const fetchProjectById = async projectId => {
  const response = await pythonAxios.get(`/projects/${projectId}`);
  return response.data;
};

export const fetchProjectData = async () => {
  const response = await pythonAxios.get(`/projects`);
  return response.data;
};

export const fetchProjectDetails = async projectId => {
  const response = await pythonAxios.get(`/projects/${projectId}`);
  return response.data;
};

export const fetchProjectNames = async projectId => {
  const response = await pythonAxios.get(`/projects/${projectId}`);
  return response?.data?.name;
};

export const createProject = async (userId, projectData) => {
  try {
    const response = await pythonAxios.post('/projects/', [projectData], {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data[0]; // Since the API returns a list, we return the first item
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
};

export const updateProject = async (projectId, projectData) => {
  try {
    const response = await pythonAxios.put(
      `/projects/${projectId}`,
      projectData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

export const deleteProject = async projectId => {
  try {
    const response = await pythonAxios.delete(`/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting project:', error);
    throw error;
  }
};

export const fetchProjects = async (skip = 0, limit = 50) => {
  try {
    const response = await pythonAxios.get(
      `/projects/?skip=${skip}&limit=${limit}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};
// Company fetchers
export const fetchCompanies = async (skip = 0, limit = 10) => {
  const response = await pythonAxios.get(
    `/companies/?skip=${skip}&limit=${limit}`,
  );
  return response.data;
};

export const createCompany = async companyData => {
  try {
    const response = await pythonAxios.post('/companies/', companyData);
    return response.data;
  } catch (error) {
    console.error('Error creating company:', error);
    throw error;
  }
};

export const updateCompany = async (companyId, companyData) => {
  try {
    const response = await pythonAxios.put(
      `/companies/${companyId}`,
      companyData,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating company:', error);
    throw error;
  }
};

export const deleteCompany = async companyId => {
  try {
    const response = await pythonAxios.delete(`/companies/${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting company:', error);
    throw error;
  }
};

export const fetchCompanyById = async companyId => {
  const response = await pythonAxios.get(`/companies/${companyId}`);
  return response.data;
};

// AccountCompany fetchers
export const fetchAccountCompanies = async (skip = 0, limit = 1000) => {
  const response = await pythonAxios.get(
    `/accounts/account_companies/?skip=${skip}&limit=${limit}`,
  );
  return response.data;
};

export const createAccountCompany = async accountCompanyData => {
  const response = await pythonAxios.post(
    '/accounts/account_companies/',
    accountCompanyData,
  );
  return response.data;
};

export const deleteAccountCompany = async accountCompanyId => {
  const response = await pythonAxios.delete(
    `/accounts/account_companies/${accountCompanyId}`,
  );
  return response.data;
};

//ProjectCompany fetchers
export const fetchProjectCompanies = async (skip = 0, limit = 10) => {
  const response = await pythonAxios.get(
    `/projects/project_companies/?skip=${skip}&limit=${limit}`,
  );
  return response.data;
};

export const createProjectCompany = async projectCompanyData => {
  const response = await pythonAxios.post(
    '/projects/project_companies/',
    projectCompanyData,
  );
  return response.data;
};

export const updateProjectCompany = async (
  projectCompanyId,
  projectCompanyData,
) => {
  const response = await pythonAxios.put(
    `/projects/project_companies/${projectCompanyId}`,
    projectCompanyData,
  );
  return response.data;
};

export const deleteProjectCompany = async projectCompanyId => {
  const response = await pythonAxios.delete(
    `/projects/project_companies/${projectCompanyId}`,
  );
  return response.data;
};

// Key Project Metrics (KPIs) fetchers
export const fetchAllKPIs = async () => {
  const response = await pythonAxios.get('/kpis/index/all');
  return response.data;
};

export const fetchKPINames = async kpiId => {
  const response = await pythonAxios.get(`/kpis/${kpiId}`);
  return response?.data?.name;
};

// User fetchers
export const fetchUsers = async () => {
  const response = await pythonAxios.get(`/users/`);
  return response.data;
};

export const createUser = async userData => {
  try {
    const response = await pythonAxios.post('/users/', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};
export const updateUser = async (userId, userData) => {
  try {
    const response = await pythonAxios.put(
      `/users/profile/${userId}`,
      userData,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async userId => {
  try {
    const response = await pythonAxios.delete(`/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const fetchUserNames = async userId => {
  const response = await pythonAxios.get(`/users/${userId}`);
  return response?.data?.username;
};

export const fetchCurrentUserProfile = async () => {
  const response = await pythonAxios.get(`/users/profile/me`);
  return response.data;
};

export const fetchUserAccounts = async userId => {
  const response = await pythonAxios.get(`/users/${userId}/accounts`);
  return response.data;
};

export const fetchUserProjects = async userId => {
  const response = await pythonAxios.get(`/users/${userId}/projects`);
  return response.data;
};

export const fetchUserCompanies = async userId => {
  const response = await pythonAxios.get(`/users/${userId}/companies`);
  return response.data;
};

export const fetchUserCompanyStakes = async userId => {
  const response = await pythonAxios.get(`/users/${userId}/company_stakes`);
  return response.data;
};

// PDF report generation api fetchers
export const fetchReportPDF = async (userId, options) => {
  try {
    const params = {
      company_ids: options.companyIds.join(','),
      project_ids: options.projectIds.join(','),
      start_date:
        options.startDate instanceof Date
          ? options.startDate.toISOString().split('T')[0]
          : options.startDate,
      end_date:
        options.endDate instanceof Date
          ? options.endDate.toISOString().split('T')[0]
          : options.endDate,
      kpi_ids: options.kpiIds.join(','),
    };

    const response = await pythonAxios.get(
      `/reports/${userId}/generate_report_pdf`,
      {
        params,
        responseType: 'blob',
      },
    );
    return response;
  } catch (error) {
    console.error('Error generating PDF report:', error);
    throw error;
  }
};

export const fetchReportData = async (userId, options) => {
  try {
    const params = {
      company_ids: options.companyIds?.join(',') || '',
      project_ids: options.projectIds?.join(',') || '',
      start_date: options.startDate || '',
      end_date: options.endDate || '',
      kpi_ids: options.kpiIds?.join(',') || '',
    };

    const response = await pythonAxios.get(`/reports/${userId}/report_data`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching report data:', error);
    throw error;
  }
};

export const api = {
  fetchProjectDetails: apiCallWrapper(fetchProjectDetails),
  fetchProjectNames: apiCallWrapper(fetchProjectNames),
  fetchKPINames: apiCallWrapper(fetchKPINames),
  fetchUserNames: apiCallWrapper(fetchUserNames),
  fetchCurrentUserProfile: apiCallWrapper(fetchCurrentUserProfile),
  fetchUserProjects: apiCallWrapper(fetchUserProjects),
  fetchProjectData: apiCallWrapper(fetchProjectData),
  fetchKPIData: apiCallWrapper(fetchKPIData),
  fetchAllKPIs: apiCallWrapper(fetchAllKPIs),
  fetchUserAccounts: apiCallWrapper(fetchUserAccounts),
  fetchUserCompanies: apiCallWrapper(fetchUserCompanies),
  fetchUserCompanyStakes: apiCallWrapper(fetchUserCompanyStakes),
  fetchAggregatedKPIs: apiCallWrapper(fetchAggregatedKPIs),
  fetchUserPortfolioKPIs: apiCallWrapper(fetchUserPortfolioKPIs),
  fetchUserPortfolioHierarchy: apiCallWrapper(fetchUserPortfolioHierarchy),
  fetchUserFinancialModelData: apiCallWrapper(fetchUserFinancialModelData),
  fetchReportPDF: apiCallWrapper(fetchReportPDF),
  fetchReportData: apiCallWrapper(fetchReportData),
  fetchAccounts: apiCallWrapper(fetchAccounts),
  createAccount: apiCallWrapper(createAccount),
  updateAccount: apiCallWrapper(updateAccount),
  deleteAccount: apiCallWrapper(deleteAccount),
  fetchCompanies: apiCallWrapper(fetchCompanies),
  createCompany: apiCallWrapper(createCompany),
  updateCompany: apiCallWrapper(updateCompany),
  deleteCompany: apiCallWrapper(deleteCompany),
};
