export const handleUploadError = (error, setUploadStatus) => {
  console.error('Error uploading files:', error);

  let errorMessage = 'File upload failed!';

  if (error.response) {
    // Server responded with a status code outside the range of 2xx
    if (error.response.status === 401) {
      errorMessage = 'Authentication failed. Please log in again.';
      // For discussion about login flow: trigger a logout action here
      // logout();
    } else if (error.response.status === 403) {
      errorMessage = 'You do not have permission to perform this action.';
    } else if (error.response.status === 404) {
      errorMessage = 'The requested resource was not found.';
    } else if (error.response.status === 500) {
      errorMessage = 'Internal server error. Please try again later.';
    } else {
      errorMessage = `Error: ${error.response.status} - ${error.response.data.message || error.response.statusText}`;
    }
  } else if (error.request) {
    // Request was made but no response received
    errorMessage = 'Network error. Please check your internet connection.';
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = `Error: ${error.message}`;
  }

  setUploadStatus({ success: false, message: errorMessage });
};
