import { DocumentPlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import * as Papa from 'papaparse';
import React, { useCallback, useEffect, useState } from 'react';

import { fetchCurrentUserProfile, fetchUserAccounts } from '../api/fetchers';
import { uploadFiles } from '../api/upload';
import AuthStatus from '../components/auth/AuthStatus';
import Code from '../components/ui-elements/CodeBlock';
import PanelCollapsible from '../components/ui-elements/PanelCollapsible';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import Loading from '../components/ui-modals/LoadingUpload';
import useTheme from '../hooks/useTheme';
import { upload_labels } from '../labels/upload_labels';
import { csv_data_example } from '../shared/constants';
import calculateSummary from '../utils/calculateSummary';
import { handleUploadError } from '../utils/errorHandler';

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [dataPreview, setDataPreview] = useState(null);
  const [summary, setSummary] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [newFiles, setNewFiles] = useState([]);

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  const handleFileChange = e => {
    if (parsedData.length > 0) {
      setShowWarning(true);
      setNewFiles([...e.target.files]);
    } else {
      setFiles([...e.target.files]);
    }
  };

  const handleRemoveFile = index => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleParseFiles = async () => {
    if (files.length === 0) {
      alert('Please select at least one file!');
      return;
    }

    setIsLoading(true);
    setUploadStatus(null);

    try {
      const parsedResults = await Promise.all(files.map(parseFile));
      setParsedData(parsedResults);
      setIsReviewing(true);
      const summaryData = calculateSummary(parsedResults);
      setSummary(summaryData);
    } catch (error) {
      handleUploadError(error, setUploadStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const parseFile = file => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy', // Skip empty lines (entirely empty rows)
        dynamicTyping: true, // Automatically type-convert numeric fields
        complete: results => {
          console.log('Parsed Data:', results.data.slice(0, 5)); // Log first 5 rows
          if (results.errors.length) {
            console.error('Errors:', results.errors); // Log any errors
            reject(results.errors);
          } else {
            resolve({ fileName: file.name, data: results.data });
          }
        },
        error: error => reject(error),
      });
    });
  };

  const handleCommit = async () => {
    setIsLoading(true);

    try {
      const data = await uploadFiles(files);
      console.log('API Response:', data); // Log the response
      if (data) {
        updateStateAfterUpload(data);
      } else {
        handleUploadError(
          new Error('No data returned from API'),
          setUploadStatus,
        );
      }
    } catch (error) {
      handleUploadError(error, setUploadStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStateAfterUpload = data => {
    if (data && data.status === 'success') {
      setDataPreview(null); // clear data preview panel
      setSummary(null); // clear summary panel
      setFiles([]); // clear selected files
      setParsedData([]); // clear parsed data
      setIsReviewing(false); // Reset review state
      setUploadStatus({ success: true, message: data.message });
    } else {
      setUploadStatus({
        success: false,
        message: data ? data.message : 'Unknown error',
      });
    }
  };

  const handleClearUploads = () => {
    setDataPreview(null);
    setSummary(null);
    setFiles([]);
    setParsedData([]);
    setIsReviewing(false);
    setUploadStatus(null);
  };

  const handleProceedWithNewFiles = () => {
    setShowWarning(false);
    handleClearUploads();
    setFiles(newFiles);
    setNewFiles([]);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
          <Typography
            variant="h4"
            className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Upload Data
          </Typography>
          <Typography
            variant="h6"
            className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Upload CSV files to the database.
            {selectedUser
              ? ` Welcome, ${selectedUser.username}!`
              : ' Loading...'}
          </Typography>
        </Box>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          <Alert severity="info" className="mb-4">
            <AlertTitle>
              Ensure your CSV includes the columns as: kpi_id, kpi_name,
              project_id, dates. Preview data after validating by selecting on
              the filename. See sample CSV format below:
            </AlertTitle>
          </Alert>
          <Code code={csv_data_example} className="mb-2"></Code>
          <h1 className="text-2xl font-bold mb-4 mt-4">
            {upload_labels.UPLOAD_CSV_FILES}
          </h1>
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-700 p-4 rounded-lg mb-4">
            <input
              type="file"
              accept=".csv"
              multiple
              onChange={handleFileChange}
              className="hidden"
              id="fileUpload"
            />
            <label
              htmlFor="fileUpload"
              className="cursor-pointer flex flex-col items-center justify-center"
            >
              <DocumentPlusIcon className="h-8 w-8 text-gray-500 dark:text-gray-400 mb-2" />
              <span className="text-gray-500 dark:text-gray-400">
                {upload_labels.DRAG_DROP_FILES}
              </span>
            </label>
          </div>

          {files.length > 0 && !isReviewing && (
            <div className="mb-4">
              <h2 className="text-xl font-semibold mb-2">
                {upload_labels.SELECTED_FILES}
              </h2>
              <ul className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
                {files.map((file, index) => (
                  <li
                    key={index}
                    className="flex justify-between items-center mb-2"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="text-red-500"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </li>
                ))}
              </ul>
              <button
                onClick={handleParseFiles}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg mt-4"
              >
                {upload_labels.PARSE_FILES}
              </button>
            </div>
          )}

          {isReviewing && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">
                {upload_labels.DATA_PREVIEW}
              </h2>
              {parsedData.map((fileData, index) => (
                <PanelCollapsible key={index} title={fileData.fileName}>
                  <pre className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
                    {JSON.stringify(fileData.data.slice(0, 10), null, 2)}
                  </pre>
                </PanelCollapsible>
              ))}
              <h2 className="text-xl font-semibold mb-2 mt-4">
                {upload_labels.SUMMARY}
              </h2>
              <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
                <p>
                  {upload_labels.TOTAL_ENTRIES}: {summary.totalEntries}
                </p>
                <p>
                  {upload_labels.NUMBER_OF_FILES}: {summary.numberOfFiles}
                </p>
                <p>{upload_labels.FILE_NAMES}:</p>
                <ul>
                  {summary.fileNames.map((fileName, index) => (
                    <li key={index}>{fileName}</li>
                  ))}
                </ul>
              </div>
              <button
                onClick={handleCommit}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg mt-4"
              >
                {upload_labels.COMMIT_TO_DATABASE}
              </button>
              <button
                onClick={handleClearUploads}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg mt-4 ml-4"
              >
                {upload_labels.CLEAR_UPLOADS}
              </button>
            </div>
          )}

          {uploadStatus && (
            <div className="mt-4">
              <h2
                className={
                  uploadStatus.success ? 'text-green-500' : 'text-red-500'
                }
              >
                {uploadStatus.success
                  ? upload_labels.UPLOAD_SUCCESSFUL
                  : upload_labels.UPLOAD_FAILED}
              </h2>
              <p>{uploadStatus.message}</p>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loading message="Uploading files, please wait..." />}

      {showWarning && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white dark:bg-gray-800 p-8 rounded shadow-lg text-center">
            <p className="mb-4">{upload_labels.WARNING_MESSAGE}</p>
            <button
              onClick={handleProceedWithNewFiles}
              className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg mr-4"
            >
              {upload_labels.PROCEED}
            </button>
            <button
              onClick={() => setShowWarning(false)}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg"
            >
              {upload_labels.CANCEL}
            </button>
          </div>
        </div>
      )}
      <AuthStatus />
    </div>
  );
};

export default UploadPage;
