import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
} from 'recharts';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const AreaChartComponent = ({ data, title }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  return (
    <Card
      style={{
        backgroundColor: 'transparent',
        color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
      }}
      className="bg-transparent dark:bg-transparent"
    >
      <CardContent>
        <Typography
          variant="h6"
          style={{
            color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
          }}
        >
          {title}
        </Typography>
        <AreaChart
          width={500}
          height={300}
          data={data}
          style={{
            backgroundColor: 'transparent',
            borderRadius: '8px',
          }}
        >
          <defs>
            <linearGradient id="colorDistributions" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="var(--chart-3)" stopOpacity={0.8} />
              <stop offset="95%" stopColor="var(--chart-3)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="period" stroke="var(--foreground)" />
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={isDarkMode ? '#333' : '#ccc'}
          />
          <Tooltip
            content={({ payload, label }) => {
              if (!payload || payload.length === 0) return null;

              const total = payload.reduce(
                (sum, entry) => sum + entry.value,
                0,
              );

              return (
                <div
                  style={{
                    backgroundColor: 'var(--popover)',
                    padding: '10px',
                    border: `1px solid var(--border)`,
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    color: 'var(--foreground)',
                  }}
                >
                  <p>{label}</p>
                  {payload.map((entry, index) => (
                    <div key={`item-${index}`}>
                      <span style={{ color: entry.color }}>{entry.name}:</span>{' '}
                      {entry.value} units
                    </div>
                  ))}
                  <div>
                    <strong>Total: {total} units</strong>
                  </div>
                </div>
              );
            }}
          />
          <Area
            type="step"
            dataKey="equity_distributions"
            stroke="var(--chart-3)"
            fillOpacity={1}
            fill="url(#colorDistributions)"
          />
          <Legend />
        </AreaChart>
      </CardContent>
    </Card>
  );
};

export default AreaChartComponent;
