import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const MetricCard = ({ title, value, change, breakdown }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const changeColor = change?.startsWith('+')
    ? 'text-green-600'
    : 'text-red-600';
  const [showDetail, setShowDetail] = useState(false);

  const formatNumber = num => {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(
      num,
    );
  };

  const yearlyBreakdown = breakdown?.filter(item =>
    item.label.match(/^\d{4}$/),
  );
  const otherBreakdown = breakdown?.filter(
    item => !item.label.match(/^\d{4}$/) && item.label !== 'Yearly Breakdown',
  );

  return (
    <Card
      style={{
        backgroundColor: 'var(--card)',
        color: 'var(--foreground)',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            style={{ color: 'var(--foreground)' }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => setShowDetail(!showDetail)}
            style={{ padding: 0, color: 'var(--foreground)' }}
          >
            {showDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Typography
          variant="h4"
          component="div"
          style={{ color: 'var(--foreground)' }}
        >
          ${formatNumber(parseFloat(value.replace(/[^0-9.-]+/g, '')))}
        </Typography>
        {change && (
          <Typography variant="body2" component="div" className={changeColor}>
            {change}
          </Typography>
        )}
        <Collapse in={showDetail}>
          {otherBreakdown && otherBreakdown.length > 0 && (
            <>
              <Divider
                style={{
                  margin: '16px 0',
                  backgroundColor: 'var(--foreground)',
                }}
              />
              {otherBreakdown.map((item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  style={{ marginBottom: '8px', color: 'var(--foreground)' }}
                >
                  {item.label}: $
                  {formatNumber(
                    parseFloat(item.value.replace(/[^0-9.-]+/g, '')),
                  )}
                </Typography>
              ))}
            </>
          )}
          {yearlyBreakdown && yearlyBreakdown.length > 0 && (
            <>
              <Divider
                style={{
                  margin: '16px 0',
                  backgroundColor: 'var(--foreground)',
                }}
              />
              <Typography
                variant="subtitle2"
                style={{ marginBottom: '8px', color: 'var(--foreground)' }}
              >
                Yearly Breakdown
              </Typography>
              <TableContainer
                component={Paper}
                style={{ backgroundColor: 'var(--card)' }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: 'var(--foreground)' }}>
                        Year
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: 'var(--foreground)' }}
                      >
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {yearlyBreakdown.map(item => (
                      <TableRow key={item.label}>
                        <TableCell style={{ color: 'var(--foreground)' }}>
                          {item.label}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: 'var(--foreground)' }}
                        >
                          $
                          {formatNumber(
                            parseFloat(item.value.replace(/[^0-9.-]+/g, '')),
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default MetricCard;
