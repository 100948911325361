import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchAllKPIs,
  fetchCurrentUserProfile,
  fetchProjectDetails,
  fetchUserAccounts,
  fetchUserProjects,
} from '../api/fetchers';
import AuthStatus from '../components/auth/AuthStatus';
import DownloadPdfButton from '../components/buttons/DownloadPdfButton';
import KeyMetricsAggregateButtonGroup from '../components/buttons/KeyMetricsAggregateButtonGroup';
import ResetDefaultsButton from '../components/buttons/ResetDefaultsButton';
import LineChart from '../components/charts/BaseChartLine';
import MixedChartSeamless from '../components/charts/BaseChartMixedSeamless';
import LineChartOptions from '../components/charts/ChartOptionsLine';
import MixedChartOptions from '../components/charts/ChartOptionsMixed';
import DataTablePage from '../components/tables/DataTablePage';
import AccountProjectDropdown from '../components/ui-elements/AccountProjectDropdown';
import KpiDropdownTiny from '../components/ui-elements/KpiDropdownTiny';
import ProjectInfoBadge from '../components/ui-elements/ProjectInfoBadge';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import UserCompaniesBadge from '../components/ui-elements/UserCompaniesBadge';
import DatabaseStatus from '../components/ui-modals/DatabaseStatus';
import useTheme from '../hooks/useTheme';
import { projectDashboardLabels } from '../labels/dashboard_labels';

const INCOME_PRD = 'Income Producing';
const CONSTRUCTION = 'Construction Land';
const DEFAULT_SELECTED_KPIS = {
  [INCOME_PRD]: [8, 9, 10, 14],
  [CONSTRUCTION]: [1, 2, 3, 4],
};
const DEFAULT_AGGREGATIONS = {
  [INCOME_PRD]: 'annual',
  [CONSTRUCTION]: 'quarterly',
};

const ProjectDashboard = () => {
  const [selectedUser, setSelectedUser] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [kpiDescriptors, setKpiDescriptors] = useState({
    [INCOME_PRD]: [],
    [CONSTRUCTION]: [],
  });
  const [selectedProjectDetails, setSelectedProjectDetails] = useState({
    project_type: INCOME_PRD,
  });

  const [projectId, setProjectId] = useState(null);
  const [selectedKpis, setSelectedKpiIds] = useState([0, 0, 0, 0]);
  const [aggregate, setAggregate] = useState('annual');

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const loadKPIDescriptors = async () => {
    const allKPIs = await fetchAllKPIs();
    setKpiDescriptors({
      [INCOME_PRD]: kpisFilteredForIncomeProducing(allKPIs),
      [CONSTRUCTION]: kpisFilteredForConstruction(allKPIs),
    });
  };

  const kpisFilteredForIncomeProducing = allKPIs => {
    return allKPIs.filter(kpi => kpi.id >= 8 && kpi.id <= 20);
  };

  const kpisFilteredForConstruction = allKPIs => {
    return allKPIs.filter(kpi => kpi.id >= 1 && kpi.id <= 7);
  };

  const setDefaultKpisAndAggregation = project => {
    const { project_type } = project;
    setSelectedKpiIds(DEFAULT_SELECTED_KPIS[project_type]);
    setAggregate(DEFAULT_AGGREGATIONS[project_type]);
  };

  const initializeDefaults = projects => {
    if (projects.length > 0) {
      const firstProject = projects[0];
      setProjectId(firstProject.id);
      setSelectedProjectDetails(firstProject);
      setDefaultKpisAndAggregation(firstProject);
    }
  };

  const fetchCurrentUserAndData = async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);
      const userProjects = await fetchUserProjects(currentUserProfile.id);
      setProjects(userProjects);
      const userAccounts = await fetchUserAccounts(currentUserProfile.id);
      setAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleUserChange = async user => {
    setSelectedUser(user);
    try {
      const userProjects = await fetchUserProjects(user.id);
      setProjects(userProjects);
      initializeDefaults(userProjects);

      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      setAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');

      const kpiList = await fetchAllKPIs();
      setKpiDescriptors(kpiList);
      setKpiDescriptors({
        [INCOME_PRD]: kpisFilteredForIncomeProducing(kpiList),
        [CONSTRUCTION]: kpisFilteredForConstruction(kpiList),
      });
    } catch (error) {
      console.error('Error fetching user projects:', error);
    }
  };

  const handleKpiChange = (index, value) => {
    const currentKpis = [...selectedKpis];
    currentKpis[index] = value;
    setSelectedKpiIds(currentKpis);
  };

  const handleProjectSelection = async projectId => {
    setProjectId(projectId);

    try {
      const projectData = await fetchProjectDetails(projectId);
      setProjectDetails(projectData);
      setSelectedProjectDetails(projectData);
      setDefaultKpisAndAggregation(projectData);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };

  const handleAccountSelection = async accountId => {
    const userProjects = await fetchUserProjects(selectedUser.id, accountId);
    setProjects(userProjects);
    initializeDefaults(userProjects);
  };

  useEffect(() => {
    loadKPIDescriptors();
    fetchCurrentUserAndData();
  }, []);

  const kpiSelectors = () => {
    return (
      <Grid container spacing={2} padding={1}>
        {selectedKpis.map((selectedKpi, index) => (
          <Grid item xs={3} key={`kpi-selector-${index}`}>
            <KpiDropdownTiny
              selectorIndex={index}
              kpiNames={kpiDescriptors[selectedProjectDetails.project_type]}
              onKpiSelect={handleKpiChange}
              selectedKpiId={selectedKpi}
              label="Select Key Metrics"
              backgroundColor="rgba(0, 250, 0, 0.04)"
              theme={currentTheme}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />

      {selectedUser && (
        <Sidebar
          onThemeSwitch={handleThemeSwitch}
          currentTheme={theme}
          selectedUser={selectedUser}
          selectedAccount={selectedAccount}
        />
      )}
      <div className="main-content flex-1 flex flex-col p-4">
        <Box
          className="card bg-white dark:bg-[var(--bg-dashboard-dark)] p-4 rounded shadow"
          display="flex"
          alignItems="flex-start"
          gap={2}
          mb={2}
        >
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <Typography
                variant="h4"
                className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
              >
                {projectDashboardLabels.TITLE}
              </Typography>
              <Typography
                variant="h6"
                padding={1}
                className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
              >
                {projectDashboardLabels.WELCOME_MESSAGE}
                {selectedUser ? selectedUser.username : 'loading...'}
              </Typography>
            </Grid>

            <Grid
              item
              xs={8}
              sm={8}
              display="flex-col p-4"
              justifyContent="right"
              alignItems="right"
            >
              <Box display="-ms-flexbox" alignItems="center" gap={1}>
                {selectedUser && (
                  <AccountProjectDropdown
                    userId={selectedUser.id}
                    onProjectSelect={handleProjectSelection}
                    onAccountSelect={handleAccountSelection}
                    label={projectDashboardLabels.ACCOUNT_DROPDOWN_LABEL}
                    label2={projectDashboardLabels.PROJECT_DROPDOWN_LABEL}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4}></Grid>

            <Grid item xs={2} sm={4} md={4}>
              <UserCompaniesBadge
                userId={selectedUser.id}
                projectId={projectId}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              {projectDetails && (
                <ProjectInfoBadge projectDetails={projectDetails} />
              )}
            </Grid>
          </Grid>
        </Box>

        <div className="flex-1 grid grid-cols-2 gap-4 md:grid-cols-2 mb-2">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <Grid container spacing={2} padding={1} alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="h4"
                  className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
                >
                  {projectDashboardLabels.PROJECT_PERFORMANCE}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                justifyContent="flex-end"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <ResetDefaultsButton
                    onClick={() => initializeDefaults(projects)}
                    label={projectDashboardLabels.RESET_BUTTON_LABEL}
                  />
                  <DownloadPdfButton
                    label={projectDashboardLabels.DOWNLOAD_BUTTON_LABEL}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <KeyMetricsAggregateButtonGroup
                    aggregate={aggregate}
                    setAggregate={setAggregate}
                  />
                </Box>
              </Grid>
            </Grid>
            {selectedUser && kpiSelectors()}
            {projectId && selectedKpis.every(kpi => kpi) && (
              <MixedChartSeamless
                projectId={projectId}
                kpiId1={selectedKpis[0]}
                kpiId2={selectedKpis[1]}
                kpiId3={selectedKpis[2]}
                kpiId4={selectedKpis[3]}
                aggregation={aggregate}
                initialOptions={MixedChartOptions}
              />
            )}
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md">
            <Typography
              variant="h4"
              className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
            >
              {projectDashboardLabels.PROJECT_DATA_TABLE}
            </Typography>
            <DataTablePage
              userId={selectedUser.id}
              projectId={projectId}
              kpiId={selectedKpis[0]}
              aggregation={aggregate}
              title=""
            />
          </div>
        </div>

        <div className="flex-1 grid grid-cols-2 gap-4 md:grid-cols-1">
          <div className="mt-4">
            <AuthStatus />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDashboard;
