import React, { useState } from 'react';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';
import LogoutButton from '../buttons/LogoutButton';
import TandemInvestorPortalButton from '../buttons/TandemInvestorPortalButton';
import UserMenu from '../ui-modals/UserMenu';

const TopNavBar = ({ onUserChange }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  return (
    <div
      className="sticky top-0 bg-white dark:bg-gray-800 text-black dark:text-white flex justify-between items-center px-4 py-2.5 shadow border-b border-gray-300 dark:border-gray-700"
      style={{
        marginLeft: '16rem',
      }}
    >
      <h1 className="text-xl font-bold">Investment Reporting Platform</h1>
      <div className="flex items-center space-x-4">
        <TandemInvestorPortalButton label="Tandem Investor Portal" />
        <LogoutButton label="Logout" />
        <UserMenu onUserChange={onUserChange} />
      </div>
    </div>
  );
};

export default TopNavBar;
