import { FormControlLabel, Switch, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchUserPortfolioKPIs } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import dataGridStyles from '../../styles/dataGridStyles';
import '../../styles/index.css';
import DataGridQuickFilterButtonGroup from '../buttons/DataGridQuickFilterButtonGroup';

const ProjectDataGrid = ({ userId, selectedCompanyIds }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewStake, setViewStake] = useState(false);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { theme } = useTheme();

  const equityContributionIds = [4];
  const capitalReturnIds = [5, 6, 7];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (selectedCompanyIds.length === 0) {
          setRows([]);
        } else {
          const portfolioKPIs = await fetchUserPortfolioKPIs(
            userId,
            selectedCompanyIds,
          );
          const formattedRows = formatDataForGrid(portfolioKPIs);
          setRows(formattedRows);
          // Initialize selectedKPIs with all KPI IDs
          setSelectedKPIs(
            Array.from(new Set(formattedRows.map(row => row.kpiId))),
          );
        }
      } catch (error) {
        console.error('Error fetching portfolio KPIs:', error);
        setRows([]);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId, selectedCompanyIds]);

  useEffect(() => {
    // Extract unique companies from rows
    const uniqueCompanies = Array.from(
      new Set(
        rows.map(row =>
          JSON.stringify({ id: row.company_id, name: row.companyName }),
        ),
      ),
    ).map(str => JSON.parse(str));
    setCompanies(uniqueCompanies);
    setSelectedCompanies(uniqueCompanies.map(company => company.id));
  }, [rows]);

  const handleCompanyFilterChange = newSelectedCompany => {
    setSelectedCompanies(prevSelected => {
      if (prevSelected.includes(newSelectedCompany)) {
        return prevSelected.filter(id => id !== newSelectedCompany);
      } else {
        return [...prevSelected, newSelectedCompany];
      }
    });
  };

  const filteredRows = useMemo(() => {
    return rows.filter(
      row =>
        selectedKPIs.includes(row.kpiId) &&
        selectedCompanies.includes(row.company_id),
    );
  }, [rows, selectedKPIs, selectedCompanies]);

  const formatDataForGrid = data => {
    return data.map((item, index) => ({
      id: `${item.company_id}-${item.project_id}-${item.kpi_id}-${index}`,
      company_id: item.company_id,
      companyName: item.company_name || 'Unknown Company',
      projectName: item.project_name || 'Unknown Project',
      kpiName: item.kpi_name || 'Unknown KPI',
      kpiId: item.kpi_id,
      value: Number(item.value_amount) || 0,
      year: item.date ? new Date(item.date).getFullYear() : 'Unknown Year',
      stakePercentage: Number(item.stake_percentage) || 0,
      projectType: item.type || 'Unknown Type',
      investmentId: item.investment_id || 'Unknown ID',
    }));
  };

  const formatCurrency = value => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = useMemo(
    () => [
      {
        field: 'companyName',
        headerName: 'Company',
        width: 150,
        groupable: true,
      },
      {
        field: 'projectName',
        headerName: 'Project',
        width: 150,
        groupable: true,
      },
      { field: 'kpiName', headerName: 'KPI', width: 150 },
      {
        field: 'value',
        headerName: viewStake ? 'Stake Value' : 'Total Value',
        width: 150,
        type: 'number',
        renderCell: params => {
          const value = params.row.value;
          const stake = params.row.stakePercentage;
          const displayValue = viewStake ? value * stake : value;
          return formatCurrency(displayValue);
        },
      },
      { field: 'year', headerName: 'Year', width: 100, type: 'integer' },
      {
        field: 'stakePercentage',
        headerName: 'Stake %',
        width: 120,
        type: 'number',
        renderCell: params => {
          if (params.value == null) return '';
          return `${(params.value * 100).toFixed(2)}%`;
        },
      },
      {
        field: 'projectType',
        headerName: 'Project Type',
        width: 150,
        groupable: true,
      },
      { field: 'investmentId', headerName: 'Investment ID', width: 120 },
    ],
    [viewStake],
  );

  const uniqueKpis = useMemo(() => {
    const kpiMap = new Map();
    rows.forEach(row => {
      if (!kpiMap.has(row.kpiId)) {
        kpiMap.set(row.kpiId, { id: row.kpiId, name: row.kpiName });
      }
    });
    return Array.from(kpiMap.values()).sort((a, b) => a.id - b.id);
  }, [rows]);

  const handleKPIFilterChange = newSelectedKPIs => {
    setSelectedKPIs(newSelectedKPIs);
  };

  const handleQuickFilter = filterType => {
    let newSelectedKPIs;
    switch (filterType) {
      case 'equityContribution':
        newSelectedKPIs = equityContributionIds;
        break;
      case 'capitalReturns':
        newSelectedKPIs = capitalReturnIds;
        break;
      case 'reset':
      default:
        newSelectedKPIs = Array.from(new Set(rows.map(row => row.kpiId)));
        break;
    }
    setSelectedKPIs(newSelectedKPIs);
    setActiveFilter(filterType);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <Typography
        variant="h6"
        className={`font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}
      >
        Project KPIs (Annual)
      </Typography>
      <DataGridQuickFilterButtonGroup
        activeFilter={activeFilter}
        handleQuickFilter={handleQuickFilter}
        uniqueKpis={uniqueKpis}
        selectedKPIs={selectedKPIs}
        onKPIFilterChange={handleKPIFilterChange}
        companies={companies}
        selectedCompanies={selectedCompanies}
        onCompanyFilterChange={handleCompanyFilterChange}
      />

      <div className="flex justify-end">
        <FormControlLabel
          control={
            <Switch
              checked={viewStake}
              onChange={() => setViewStake(prev => !prev)}
              color="primary"
            />
          }
          label="View Company Stake"
          className={theme === 'dark' ? 'text-white' : 'text-black'}
        />
      </div>
      <div className="h-[600px] w-full">
        <DataGrid
          rows={filteredRows}
          columns={columns}
          loading={loading}
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
          checkboxSelection
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: 'year', sort: 'desc' }],
            },
            aggregation: {
              model: {
                value: 'sum',
              },
            },
          }}
          className={`
            ${theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-black'}
            border-none
          `}
          sx={{
            ...Object.keys(dataGridStyles).reduce((acc, key) => {
              const styleKey = `& .MuiDataGrid-${key}`;
              acc[styleKey] = dataGridStyles[key];
              return acc;
            }, {}),
            '& .MuiTablePagination-root': {
              color: 'var(--foreground)',
            },
            '& .MuiTablePagination-selectIcon': {
              color: 'var(--foreground)',
            },
            '& .MuiTablePagination-actions': {
              '& .MuiIconButton-root': {
                color: 'var(--foreground)',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProjectDataGrid;
