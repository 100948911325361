import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createProjectCompany,
  deleteProjectCompany,
  fetchCompanies,
  fetchProjectCompanies,
  fetchProjects,
  updateProjectCompany,
} from '../../api/fetchers';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminProjectCompanyPanel = () => {
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedProjectCompany, setSelectedProjectCompany] = useState(null);
  const [newProjectCompany, setNewProjectCompany] = useState({
    project_id: '',
    company_id: '',
    exposure_percentage: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    projectCompanyId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [projectCompaniesData, projectsData, companiesData] =
          await Promise.all([
            fetchProjectCompanies(0, 1000),
            fetchProjects(0, 50),
            fetchCompanies(0, 50),
          ]);
        setProjectCompanies(projectCompaniesData);
        setProjects(projectsData);
        setCompanies(companiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setToast({
          open: true,
          message: 'Error loading data. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewProjectCompany(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddProjectCompany = async () => {
    try {
      const createdProjectCompany =
        await createProjectCompany(newProjectCompany);
      setProjectCompanies([...projectCompanies, createdProjectCompany]);
      resetForm();
      setToast({
        open: true,
        message: 'Project-Company relation added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding project-company relation:', error);
      setToast({
        open: true,
        message: 'Error adding project-company relation. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleEditProjectCompany = projectCompanyId => {
    const projectCompanyToEdit = projectCompanies.find(
      pc => pc.id === projectCompanyId,
    );
    setSelectedProjectCompany(projectCompanyToEdit);
    setNewProjectCompany({ ...projectCompanyToEdit });
  };

  const handleUpdateProjectCompany = async () => {
    try {
      const updatedProjectCompany = await updateProjectCompany(
        selectedProjectCompany.id,
        newProjectCompany,
      );
      setProjectCompanies(
        projectCompanies.map(pc =>
          pc.id === selectedProjectCompany.id ? updatedProjectCompany : pc,
        ),
      );
      resetForm();
      setToast({
        open: true,
        message: 'Project-Company relation updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating project-company relation:', error);
      setToast({
        open: true,
        message: 'Error updating project-company relation. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  const resetForm = () => {
    setSelectedProjectCompany(null);
    setNewProjectCompany({
      project_id: '',
      company_id: '',
      exposure_percentage: '',
    });
  };

  const handleDeleteClick = projectCompanyId => {
    const projectCompanyToDelete = projectCompanies.find(
      pc => pc.id === projectCompanyId,
    );
    setConfirmDialog({
      open: true,
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete this project-company relation? This action cannot be undone.`,
      projectCompanyId: projectCompanyId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteProjectCompany(confirmDialog.projectCompanyId);
      setProjectCompanies(
        projectCompanies.filter(pc => pc.id !== confirmDialog.projectCompanyId),
      );
      setToast({
        open: true,
        message: 'Project-Company relation deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting project-company relation:', error);
      setToast({
        open: true,
        message: 'Error deleting project-company relation. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'project_name', headerName: 'Project Name', width: 200 },
    { field: 'company_name', headerName: 'Company Name', width: 200 },
    {
      field: 'exposure_percentage',
      headerName: 'Exposure %',
      width: 150,
      renderCell: params => `${(params.value * 100).toFixed(2)}%`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditProjectCompany(params.row.id)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Project-Company Relations
      </Typography>
      <Box className="h-[600px] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={projectCompanies}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Project</InputLabel>
              <Select
                name="project_id"
                value={newProjectCompany.project_id}
                onChange={handleInputChange}
                required
              >
                {projects.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Company</InputLabel>
              <Select
                name="company_id"
                value={newProjectCompany.company_id}
                onChange={handleInputChange}
                required
              >
                {companies.map(company => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="exposure_percentage"
              label="Exposure Percentage"
              value={newProjectCompany.exposure_percentage}
              onChange={handleInputChange}
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 100, step: 0.01 }}
              required
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedProjectCompany ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateProjectCompany}
                >
                  Update Relation
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddProjectCompany}
              >
                Add Relation
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminProjectCompanyPanel;
