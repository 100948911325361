import React, { useState } from 'react';
import Loading from './Loading';
import pythonAxios from '../../services/axiosConfigPython';
import { app_paths } from '../../App';

const SignupModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/auth/signup', { name, email, password });
      setSuccess('Signup successful! Logging you in...');

      // Automatically log the user in
      const loginResponse = await pythonAxios.post('/auth/login', {
        email,
        password,
      });
      const { token } = loginResponse.data;

      // Save the token to localStorage or context
      localStorage.setItem('token', token);

      setLoading(false);
      onClose();

      // Redirect to dashboard
      window.location.href = app_paths.PROJECTS_DASHBOARD_PATH;
    } catch (error) {
      console.error(error);
      setError('Failed to sign up. Please try again.');
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white p-8 rounded shadow-lg z-10">
        {loading ? (
          <Loading message="Signing up..." />
        ) : (
          <>
            <h2 className="text-2xl mb-4">Sign Up</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2">Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Password"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded"
              >
                Sign Up
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupModal;
