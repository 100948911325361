import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import '../../styles/index.css';

const KpiFilterDropdown = ({ kpis, selectedKPIs, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = kpiId => {
    const newSelectedKPIs = selectedKPIs.includes(kpiId)
      ? selectedKPIs.filter(id => id !== kpiId)
      : [...selectedKPIs, kpiId];
    onFilterChange(newSelectedKPIs);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: 'primary.main',
          color: 'var(--primary-foreground)',
          '&:hover': {
            backgroundColor: 'secondary.dark',
          },
        }}
      >
        KPI Filters
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
          },
        }}
      >
        {kpis.map(kpi => (
          <MenuItem key={kpi.id} onClick={() => handleToggle(kpi.id)}>
            <Checkbox
              checked={selectedKPIs.includes(kpi.id)}
              sx={{
                color: 'var(--foreground)',
                '&.Mui-checked': {
                  color: 'var(--accent-color)',
                },
              }}
            />
            <ListItemText
              primary={kpi.name}
              sx={{ color: 'var(--foreground)' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default KpiFilterDropdown;
