import pythonAxios from '../services/axiosConfigPython';

export const uploadFiles = async files => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });

  const response = await pythonAxios.post(
    '/uploads/kpis/multi-file',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};
