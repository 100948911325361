export const menu_labels = {
  PROJECTS_DASHBOARD_TITLE: 'Project Dashboard',
  PORTFOLIO_DASHBOARD_TITLE: 'Portfolio Dashboard',
  PROJECTS_PAGE_TITLE: 'Projects',
  UPLOAD_PAGE_TITLE: 'Upload Data',
  REPORTS_PAGE_TITLE: 'Reports',
  SETTINGS_PAGE_TITLE: 'Settings',
  ACCOUNT_PAGE_TITLE: 'Account',
  SUPPORT_PAGE_TITLE: 'Support',
};

export const usermenu_labels = {
  PROFILE_PAGE_TITLE: 'Profile',
  SETTINGS_PAGE_TITLE: 'Settings',
  LOGOUT_BUTTON_TITLE: 'Logout',
};
