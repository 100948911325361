import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchAggregatedKPIs,
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUserProjects,
} from '../api/fetchers';
import AuthStatus from '../components/auth/AuthStatus';
import AreaChartComponent from '../components/charts/BaseChartArea.js';
import StackedColumnChartComponent from '../components/charts/BaseChartStackedColumnTotals.js';
import LineChartCumulativeDistributions from '../components/charts/ChartCumulativeDistributions.js';
import DonutChart from '../components/charts/DonutChart';
import CompanyStakesCollapsibleTable from '../components/tables/CompanyStakesCollapsibleTable';
import FinancialModelDataGrid from '../components/tables/FinancialModelDataGrid.js';
import PortfolioHierarchyGrid from '../components/tables/PortfolioHierarchyDataGrid.js';
import ProjectDataGrid from '../components/tables/ProjectDataGrid.js';
import ProjectSummaryTable from '../components/tables/ProjectSummaryTable';
import CompanyDropdown from '../components/ui-elements/CompanyDropdown';
import MetricCard from '../components/ui-elements/MetricCard';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';
import { portfolioDashboardLabels } from '../labels/dashboard_labels';
import {
  areaChartData,
  columnChartData,
  cumulativeVsQuarterlyData,
  donutChartData,
} from '../shared/dummyChartData.js';
import ErrorBoundary from '../utils/errorBoundary.js';

const PortfolioDashboard = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [kpiData, setKpiData] = useState({
    equityContributed: { total: 0, yearly: [] },
    capitalReturns: { total: 0, breakdown: {}, yearly: [] },
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchCurrentUserAndProjects = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userProjects =
        (await fetchUserProjects(currentUserProfile.id)) || [];
      setProjects(userProjects);
      setFilteredProjects(userProjects);

      const userCompanies =
        (await fetchUserCompanies(currentUserProfile.id)) || [];
      setCompanies(userCompanies);
      setFilteredCompanies(userCompanies);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      setAccounts(userAccounts);
      setFilteredAccounts(userAccounts);

      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');

      return currentUserProfile;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  const fetchData = useCallback(async user => {
    if (!user || !user.id) {
      return;
    }

    try {
      const data = await fetchAggregatedKPIs(user.id, [4, 5, 6, 7], 'annual');

      if (!data || data.length === 0) {
        return;
      }

      const processedData = {
        equityContributed: { total: 0, yearly: [] },
        capitalReturns: { total: 0, breakdown: {}, yearly: [] },
      };

      data.forEach(kpi => {
        if (kpi.kpi_id === 4) {
          processedData.equityContributed.total = kpi.total_value;
          processedData.equityContributed.yearly = kpi.data.map(item => ({
            year: new Date(item.date).getFullYear(),
            value: item.amount_value,
          }));
        } else {
          processedData.capitalReturns.total += kpi.total_value;
          processedData.capitalReturns.breakdown[kpi.kpi_name] =
            kpi.total_value;
          kpi.data.forEach(item => {
            const year = new Date(item.date).getFullYear();
            const existingYear = processedData.capitalReturns.yearly.find(
              y => y.year === year,
            );
            if (existingYear) {
              existingYear.value += item.amount_value;
            } else {
              processedData.capitalReturns.yearly.push({
                year: year,
                value: item.amount_value,
              });
            }
          });
        }
      });

      setKpiData(processedData);
    } catch (error) {
      console.error('Error fetching KPI data:', error);
    }
  }, []);

  const calculateChange = yearly => {
    if (yearly.length < 2) return '0%';
    const lastYear = yearly[yearly.length - 1].value;
    const secondLastYear = yearly[yearly.length - 2].value;
    const change = ((lastYear - secondLastYear) / secondLastYear) * 100;
    return change > 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`;
  };

  useEffect(() => {
    const initializeData = async () => {
      const user = await fetchCurrentUserAndProjects();
      if (user) {
        await fetchData(user);
      }
    };

    initializeData();
  }, [fetchCurrentUserAndProjects, fetchData]);

  const handleUserChange = useCallback(
    async user => {
      setSelectedUser(user);
      try {
        const userProjects = (await fetchUserProjects(user.id)) || [];
        setProjects(userProjects);
        setFilteredProjects(userProjects);

        const userCompanies = (await fetchUserCompanies(user.id)) || [];
        setCompanies(userCompanies);

        const userAccounts = (await fetchUserAccounts(user.id)) || [];
        setAccounts(userAccounts);
        setFilteredAccounts(userAccounts);

        const defaultAccount =
          userAccounts.find(acc => acc.name === `${user.username} Account`) ||
          userAccounts[0];
        setSelectedAccount(defaultAccount ? defaultAccount : '');
        await fetchData(user);
      } catch (error) {
        console.error('Error fetching user projects:', error);
      }
    },
    [fetchData],
  );

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const allUserProjects = await fetchUserProjects(userId);
        setProjects(allUserProjects);
      } catch (error) {
        console.error('Error fetching user projects:', error);
      }
    };

    if (userId) {
      loadProjects();
    }
  }, [userId]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabStyle = {
    '&.Mui-selected:hover': {
      color: '#fff',
    },
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box
          className="card bg-white dark:bg-[var(--bg-dashboard-dark)] p-4 rounded shadow"
          mb={1}
        >
          <Grid container spacing={2} alignItems="top">
            <Grid item xs={8}>
              <Typography
                variant="h4"
                className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
              >
                {portfolioDashboardLabels.TITLE}
              </Typography>
              <Typography
                variant="h6"
                padding={1}
                className={`font-bold ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
              >
                {portfolioDashboardLabels.WELCOME_MESSAGE}
                {selectedUser ? selectedUser.username : 'loading...'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              display="flex-col p-4"
              justifyContent="right"
              alignItems="right"
            ></Grid>
            <Grid item xs={12} sm={4}>
              <CompanyStakesCollapsibleTable userId={selectedUser?.id} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MetricCard
                title={portfolioDashboardLabels.TOTAL_EQUITY_CONTRIBUTION}
                value={`$${kpiData.equityContributed.total.toLocaleString()}`}
                change={calculateChange(kpiData.equityContributed.yearly)}
                breakdown={kpiData.equityContributed.yearly.map(item => ({
                  label: item.year.toString(),
                  value: `$${item.value.toLocaleString()}`,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MetricCard
                title={portfolioDashboardLabels.TOTAL_CAPITAL_RETURNS}
                value={`$${kpiData.capitalReturns.total.toLocaleString()}`}
                change={calculateChange(kpiData.capitalReturns.yearly)}
                breakdown={[
                  ...Object.entries(kpiData.capitalReturns.breakdown).map(
                    ([key, value]) => ({
                      label: key,
                      value: `$${value.toLocaleString()}`,
                    }),
                  ),
                  { label: 'Yearly Breakdown', value: '' },
                  ...kpiData.capitalReturns.yearly.map(item => ({
                    label: item.year.toString(),
                    value: `$${item.value.toLocaleString()}`,
                  })),
                ]}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="bg-white dark:bg-card p-4 rounded shadow" mb={3}>
          <Box mb={2}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Portfolio Overview" sx={tabStyle} />
              <Tab label="Project Data" sx={tabStyle} />
              <Tab label="Financial Model" sx={tabStyle} />
              <Tab label="Charts" sx={tabStyle} />
              <Tab label="Project Summary Table" sx={tabStyle} />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className="bg-[var(--bg-transparent)]"
            >
              <Grid item xs={12} sm={12}>
                <ErrorBoundary>
                  {selectedUser ? (
                    <PortfolioHierarchyGrid userId={selectedUser.id} />
                  ) : (
                    <CircularProgress />
                  )}
                </ErrorBoundary>
              </Grid>
            </Grid>
          )}
          {selectedTab === 1 && (
            <ErrorBoundary>
              <ProjectDataGrid
                userId={selectedUser?.id}
                selectedCompanyIds={companies.map(company => company.id)}
              />
            </ErrorBoundary>
          )}
          {selectedTab === 2 && (
            <ErrorBoundary>
              <FinancialModelDataGrid userId={selectedUser?.id} />
            </ErrorBoundary>
          )}
          {selectedTab === 3 && (
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className="bg-[var(--bg-transparent)]"
            >
              <Grid item xs={12} sm={4}>
                <LineChartCumulativeDistributions
                  data={cumulativeVsQuarterlyData}
                  title={portfolioDashboardLabels.DISTRIBUTIONS}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StackedColumnChartComponent
                  data={columnChartData}
                  title={portfolioDashboardLabels.UNIT_CLOSINGS_PROTOTYPE_CHART}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <AreaChartComponent
                  data={areaChartData}
                  title={
                    portfolioDashboardLabels.QUARTERLY_DISTRIBUTIONS_AREA_STEP
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DonutChart data={donutChartData} title="Project Stakes" />
              </Grid>
            </Grid>
          )}
          {selectedTab === 4 && (
            <ProjectSummaryTable projects={filteredProjects} title="Projects" />
          )}
        </Box>
        <AuthStatus />
      </div>
    </div>
  );
};

export default PortfolioDashboard;
