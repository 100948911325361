import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchUserCompanyStakes } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const UserCompaniesBadge = ({ userId, projectId }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [companyStakes, setCompanyStakes] = useState([]);

  useEffect(() => {
    const fetchCompanyStakes = async () => {
      try {
        const response = await fetchUserCompanyStakes(userId);
        setCompanyStakes(
          response.filter(stake => stake.project_id === projectId),
        );
      } catch (error) {
        console.error('Error fetching company stakes:', error);
      }
    };
    fetchCompanyStakes();
  }, [userId, projectId]);

  return (
    <Card
      sx={{
        minWidth: 300,
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        backgroundColor: 'var(--card)',
        color: 'var(--foreground)',
        marginTop: '0rem',
      }}
    >
      <CardContent>
        <Typography variant="h6" fontWeight="bold" component="div">
          User Companies and Stakes
        </Typography>
        {companyStakes.length === 0 ? (
          <Typography
            variant="body2"
            component="div"
            gutterBottom
            style={{ color: 'var(--foreground)' }}
          >
            No stakes found for the selected project.
          </Typography>
        ) : (
          companyStakes.map(stake => (
            <Box
              key={stake.investment_id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={1}
            >
              <Typography
                variant="body2"
                style={{ color: 'var(--foreground)' }}
              >
                {stake.company_name}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: 'var(--accent-color)' }}
              >
                {(stake.stake_percentage * 100).toFixed(2)}%
              </Typography>
            </Box>
          ))
        )}
      </CardContent>
    </Card>
  );
};

export default UserCompaniesBadge;
