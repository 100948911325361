import React from 'react';
import { Download as ButtonIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import '../../styles/index.css';

const DownloadPdfButton = ({ onClick, label }) => (
  <Button
    variant="contained"
      onClick={onClick}
      startIcon={<ButtonIcon className='w-5 h-5'/>}
    sx={{
      padding: '6px 12px',
      height: '40px',
      backgroundColor: 'var(--bg-secondary-light)', 
      color: 'var(--bg-primary-dark)', 
      '&:hover': {
        backgroundColor: 'var(--accent-color)', 
      },
      transition: 'background-color 0.3s, color 0.3s', 
    }}
  >
    {label}
  </Button>
);

export default DownloadPdfButton;
