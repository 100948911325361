import { Divider, Menu, MenuItem, Pagination } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { fetchUsers } from '../../api/fetchers';
import { usermenu_labels } from '../../labels/menu_labels';
import UserMenuButton from '../buttons/UserMenuButton';

function UserMenu({ onUserChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const usersPerPage = 10;

  useEffect(() => {
    async function fetchAllUsers() {
      try {
        const users = await fetchUsers();
        setAllUsers(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    fetchAllUsers();
  }, []);

  const paginatedUsers = useMemo(() => {
    const startIndex = (page - 1) * usersPerPage;
    return allUsers.slice(startIndex, startIndex + usersPerPage);
  }, [allUsers, page]);

  const totalPages = Math.ceil(allUsers.length / usersPerPage);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserChange = user => {
    setSelectedUser(user);
    onUserChange(user);
    handleMenuClose();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <UserMenuButton label="User Menu" onClick={handleMenuOpen} />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: '80vh',
            width: '250px',
          },
        }}
      >
        {paginatedUsers.map(user => (
          <MenuItem key={user.id} onClick={() => handleUserChange(user)}>
            {user.username}
          </MenuItem>
        ))}
        <Divider />
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="small"
          sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
        />
        <Divider />
        <MenuItem onClick={handleMenuClose}>
          {usermenu_labels.PROFILE_PAGE_TITLE}
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          {usermenu_labels.SETTINGS_PAGE_TITLE}
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          {usermenu_labels.LOGOUT_BUTTON_TITLE}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
