import { Box } from '@mui/material';
import React from 'react';

import '../../styles/index.css';

const CodeBlock = ({ code }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--bg-secondary-light)', // Use your CSS variable
        borderRadius: '0.375rem', // Tailwind's rounded-md
        padding: '1rem', // Tailwind's p-4
        fontFamily: 'monospace',
        fontSize: '0.875rem', // Tailwind's text-sm
        whiteSpace: 'pre-wrap', // Preserves whitespace and line breaks
        overflowX: 'auto', // Allows horizontal scrolling if content is too wide
        border: '1px solid var(--border-light)', // Use your CSS variable
      }}
    >
      <pre>{code}</pre>
    </Box>
  );
};

const Code = ({ code }) => {
  return <CodeBlock code={code} />; // Ensure `code` prop is passed to `CodeBlock`
};

export default Code;
