import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Button } from '@mui/material';
import { Logout as ButtonIcon } from '@mui/icons-material';
import '../../styles/index.css';
import { app_paths } from '../../App';

const LogoutButton = ({ label }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    sessionStorage.clear();
    navigate(app_paths.LOGIN_PATH);
  };

  return (
    <Tooltip title="Log out" arrow>
      <Button
        variant="contained"
          onClick={handleButtonClick}
          startIcon={<ButtonIcon className='w-5 h-5'/>}
        sx={{
          padding: '6px 12px',
          height: '40px',
          backgroundColor: 'var(--bg-secondary-light)', 
          color: 'var(--bg-primary-dark)', 
          '&:hover': {
            backgroundColor: 'var(--accent-color)', 
          },
          transition: 'background-color 0.3s, color 0.3s', 
    }}
  >
      {label}
      </Button>
    </Tooltip>
  );
};

export default LogoutButton;