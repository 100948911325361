import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUsers,
} from '../api/fetchers';
import AdminAccountCompanyPanel from '../components/admin/AdminAccountCompanyPanel';
import AdminAccountPanel from '../components/admin/AdminAccountPanel';
import AdminCompanyPanel from '../components/admin/AdminCompanyPanel';
import AdminProjectCompanyPanel from '../components/admin/AdminProjectCompanyPanel';
import AdminUserPanel from '../components/admin/AdminUserPanel';
import AuthStatus from '../components/auth/AuthStatus';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';

const Settings = () => {
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    const loadData = async () => {
      try {
        const [usersData, accountsData, companiesData] = await Promise.all([
          fetchUsers(),
          fetchUserAccounts(),
          fetchUserCompanies(),
        ]);
        setUsers(usersData);
        setAccounts(accountsData);
        setCompanies(companiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    loadData();
  }, []);

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = await fetchUserAccounts(user.id);
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
          <Typography
            variant="h4"
            className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Settings
          </Typography>
          <Typography
            variant="h6"
            className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Manage users, accounts, companies, and project stakes.
            {selectedUser
              ? ` Welcome, ${selectedUser.username}!`
              : ' Loading...'}
          </Typography>
        </Box>

        <Box className="bg-white dark:bg-card p-4 rounded shadow" mb={3}>
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Users" />
            <Tab label="Accounts" />
            <Tab label="Companies" />
            <Tab label="Account-Companies" />
            <Tab label="Company-Projects" />
          </Tabs>
          <Box sx={{ height: 'calc(100% - 48px)' }}>
            {selectedTab === 0 && <AdminUserPanel />}
            {selectedTab === 1 && <AdminAccountPanel />}
            {selectedTab === 2 && <AdminCompanyPanel />}
            {selectedTab === 3 && <AdminAccountCompanyPanel />}
            {selectedTab === 4 && <AdminProjectCompanyPanel />}
          </Box>
        </Box>
      </div>
      <AuthStatus />
    </div>
  );
};

export default Settings;
