import { Menu as UserMenuIcon } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import React from 'react';

import '../../styles/index.css';

const UserMenuButton = ({ label, onClick }) => {
  return (
    <Tooltip title={label} arrow>
      <Button
        variant="contained"
        onClick={onClick}
        startIcon={<UserMenuIcon sx={{ width: '20px', height: '20px' }} />}
        sx={{
          padding: '6px 12px',
          backgroundColor: 'var(--bg-secondary-light)',
          color: 'var(--bg-primary-dark)',
          '&:hover': {
            backgroundColor: 'var(--accent-color)',
            color: 'var(--bg-secondary-light)',
            '& .MuiSvgIcon-root': {
              color: 'var(--bg-secondary-light)',
            },
          },
          transition: 'background-color 0.3s, color 0.3s',
        }}
        aria-label={label}
      >
        {label}
      </Button>
    </Tooltip>
  );
};

export default UserMenuButton;
