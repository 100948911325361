import { useState, useEffect } from 'react';
import { fetchKPIData } from '../api/fetchers';

const useKPIData = (projectId, kpiId, aggregation) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchKPIData(projectId, kpiId, aggregation);
        setData(data);
      } catch (err) {
        setError(err);
        console.error(
          'Error fetching KPI data with function: ',
          useKPIData.name,
          error,
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId, kpiId, aggregation]);

  return { data, loading, error };
};

export default useKPIData;
