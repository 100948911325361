// Example data for MetricCard, DonutChart, AreaChart, StackedColumnChart, LineChart
export const totalEquityContribution = '$1,234,567';
export const equityChange = '+5.2%';
export const donutChartData = [
  { name: 'Project A', value: 25 },
  { name: 'Project B', value: 30 },
  { name: 'Project C', value: 20 },
  { name: 'Project D', value: 25 },
];
export const cumulativeVsQuarterlyData = [
  { period: 'Q1', cumulative: 100, quarterly: 100 },
  { period: 'Q2', cumulative: 250, quarterly: 150 },
  { period: 'Q3', cumulative: 400, quarterly: 150 },
  { period: 'Q4', cumulative: 600, quarterly: 200 },
];
export const areaChartData = [
  { period: 'Q1-2024', equity_distributions: 45000 },
  { period: 'Q2-2024', equity_distributions: 50000 },
  { period: 'Q3-2024', equity_distributions: 55000 },
  { period: 'Q4-2024', equity_distributions: 60000 },
  { period: 'Q1-2025', equity_distributions: 65000 },
  { period: 'Q2-2025', equity_distributions: 70000 },
  { period: 'Q3-2025', equity_distributions: 75000 },
  { period: 'Q4-2025', equity_distributions: 80000 },
];
export const columnChartData = [
  {
    year: '2020',
    ProjectAntonio: 450,
    ProjectBarbados: 300,
    ProjectColorado: 200,
    ProjectDenver: 150,
    ProjectEcho: 100,
  },
  {
    year: '2021',
    ProjectAntonio: 380,
    ProjectBarbados: 420,
    ProjectColorado: 250,
    ProjectDenver: 200,
    ProjectEcho: 150,
  },
  {
    year: '2022',
    ProjectAntonio: 520,
    ProjectBarbados: 120,
    ProjectColorado: 300,
    ProjectDenver: 250,
    ProjectEcho: 200,
  },
  {
    year: '2023',
    ProjectAntonio: 140,
    ProjectBarbados: 550,
    ProjectColorado: 350,
    ProjectDenver: 300,
    ProjectEcho: 250,
  },
  {
    year: '2024',
    ProjectAntonio: 600,
    ProjectBarbados: 350,
    ProjectColorado: 400,
    ProjectDenver: 450,
    ProjectEcho: 300,
  },
];
