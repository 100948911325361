import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { app_paths } from '../App';
import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  fetchUserProjects,
} from '../api/fetchers';
import AdminProjectPanel from '../components/admin/AdminProjectPanel';
import AuthStatus from '../components/auth/AuthStatus';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchCurrentUserAndProjects = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userProjects = await fetchUserProjects(currentUserProfile.id);
      setProjects(userProjects);

      const userAccounts = await fetchUserAccounts(currentUserProfile.id);
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');

      return currentUserProfile;
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndProjects();
  }, [fetchCurrentUserAndProjects]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    setLoading(true);
    try {
      const userProjects = await fetchUserProjects(user.id);
      setProjects(userProjects);

      const userAccounts = await fetchUserAccounts(user.id);
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'project_type', headerName: 'Project Type', width: 150 },
    { field: 'project_sub_type', headerName: 'Project Subtype', width: 150 },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 120,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 120,
    },
    {
      field: 'disclaimer',
      headerName: 'Disclaimer',
      width: 200,
    },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
          <Typography
            variant="h4"
            className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Projects
          </Typography>
          <Typography
            variant="h6"
            className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Manage and view all projects here.
            {selectedUser
              ? ` Welcome, ${selectedUser.username}!`
              : ' Loading...'}
          </Typography>
        </Box>

        <Box
          className="bg-white dark:bg-card p-4 rounded shadow flex-grow"
          mb={3}
        >
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="All Projects" />
            <Tab label="Admin Panel" />
          </Tabs>

          <Box sx={{ height: 'calc(100% - 48px)' }}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 400,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {selectedTab === 0 && (
                  <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={projects}
                      columns={columns}
                      pageSize={25}
                      rowsPerPageOptions={[10, 25, 50]}
                      checkboxSelection
                      disableSelectionOnClick
                    />
                  </Box>
                )}

                {selectedTab === 1 && (
                  <AdminProjectPanel
                    projects={projects}
                    setProjects={setProjects}
                    userId={selectedUser?.id}
                  />
                )}
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(app_paths.PROJECTS_DASHBOARD_PATH)}
          >
            View Project Dashboard
          </Button>
        </Box>
      </div>
      <AuthStatus />
    </div>
  );
};

export default Projects;
