import { CircularProgress } from '@mui/material';
import React from 'react';

const Loading = ({ message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
      <div className="bg-gray-800 p-10 rounded-lg shadow-2xl text-center border border-gray-700">
        <CircularProgress size={50} className="text-blue-400 mb-6" />
        <p className="text-white text-xl font-semibold">{message}</p>
        <p className="text-gray-400 mt-2 text-sm">Welcome</p>
      </div>
    </div>
  );
};

export default Loading;
