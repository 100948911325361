import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchCurrentUserProfile,
  fetchUserAccounts,
  updateUserProfile,
} from '../api/fetchers';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';

const Account = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const profile = await fetchCurrentUserProfile();
        setUserProfile(profile);
        setFormData(prevState => ({
          ...prevState,
          email: profile.email,
        }));
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    loadUserProfile();
  }, []);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = await fetchUserAccounts(user.id);
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords don't match");
      return;
    }
    try {
      const updatedProfile = await updateUserProfile(userProfile.id, {
        email: formData.email,
        password: formData.password,
      });
      setUserProfile(updatedProfile);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
          <Typography
            variant="h4"
            className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Account Settings
          </Typography>
          <Typography
            variant="h6"
            className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Update your account details below.
          </Typography>
        </Box>

        <Box className="bg-white dark:bg-card p-4 rounded shadow">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default Account;
