import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useContext, useEffect, useState } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import useKPIData from '../../hooks/useKPIData';
import MixedChartOptions from './../charts/ChartOptionsMixed';

const MixedChartSeamless = ({
  projectId,
  kpiId1,
  kpiId2,
  kpiId3,
  kpiId4,
  aggregation,
  initialOptions,
}) => {
  const { theme } = useContext(ThemeContext);
  const [chartOptions, setChartOptions] = useState({
    ...initialOptions(theme),
    series: [],
  });

  const { data: data1 } = useKPIData(projectId, kpiId1, aggregation);
  const { data: data2 } = useKPIData(projectId, kpiId2, aggregation);
  const { data: data3 } = useKPIData(projectId, kpiId3, aggregation);
  const { data: data4 } = useKPIData(projectId, kpiId4, aggregation);

  useEffect(() => {
    const updateSeries = () => {
      const kpiNames = [data1, data2, data3, data4].map((data, index) =>
        data.length > 0 ? data[0].kpi_name : `KPI ${index + 1}`,
      );

      const updatedSeries = [data1, data2, data3, data4].map((data, index) => ({
        type: 'column',
        name: kpiNames[index],
        data: data.map(item => [new Date(item.date).getTime(), item.value]),
      }));

      setChartOptions(prevOptions => ({
        ...prevOptions,
        ...MixedChartOptions(theme, aggregation),
        series: updatedSeries,
      }));
    };

    updateSeries();
  }, [data1, data2, data3, data4, theme]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default React.memo(MixedChartSeamless, (prevProps, nextProps) => {
  return (
    prevProps.projectId === nextProps.projectId &&
    prevProps.kpiId1 === nextProps.kpiId1 &&
    prevProps.kpiId2 === nextProps.kpiId2 &&
    prevProps.kpiId3 === nextProps.kpiId3 &&
    prevProps.kpiId4 === nextProps.kpiId4 &&
    prevProps.aggregation === nextProps.aggregation &&
    prevProps.theme === nextProps.theme
  );
});
