import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const LineChartCumulativeDistributions = ({ data, title }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [view, setView] = useState('cumulative');

  const handleChangeView = newView => {
    setView(newView);
  };

  return (
    <Card
      style={{
        backgroundColor: 'transparent',
        color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
      }}
      className="bg-transparent dark:bg-transparent"
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ color: 'var(--foreground)' }}>
            {title}
          </Typography>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              onClick={() => handleChangeView('cumulative')}
              style={{
                backgroundColor:
                  view === 'cumulative' ? 'var(--accent-color)' : 'transparent',
                color:
                  view === 'cumulative'
                    ? 'var(--primary-foreground)'
                    : 'var(--foreground)',
                border: `1px solid ${view === 'cumulative' ? 'var(--accent-color)' : 'var(--border)'}`,
              }}
            >
              Cumulative
            </Button>
            <Button
              onClick={() => handleChangeView('quarterly')}
              style={{
                backgroundColor:
                  view === 'quarterly' ? 'var(--accent-color)' : 'transparent',
                color:
                  view === 'quarterly'
                    ? 'var(--primary-foreground)'
                    : 'var(--foreground)',
                border: `1px solid ${view === 'quarterly' ? 'var(--accent-color)' : 'var(--border)'}`,
              }}
            >
              Quarterly
            </Button>
          </ButtonGroup>
        </Box>
        <BarChart width={500} height={300} data={data}>
          <Bar
            dataKey={view === 'cumulative' ? 'cumulative' : 'quarterly'}
            fill={view === 'cumulative' ? 'var(--chart-1)' : 'var(--chart-2)'}
          />
          <CartesianGrid stroke={isDarkMode ? '#333' : '#ccc'} />
          <XAxis dataKey="period" stroke="var(--foreground)" />
          <YAxis stroke="var(--foreground)" />
          <Tooltip
            cursor={{
              fill: `var(${isDarkMode ? '--muted-foreground' : '--muted-foreground'})`,
              opacity: 0.1, // Adjust the opacity if needed
            }}
            content={({ payload, label }) => {
              if (!payload || payload.length === 0) return null;

              const total = payload.reduce(
                (sum, entry) => sum + entry.value,
                0,
              );

              return (
                <div
                  style={{
                    backgroundColor: 'var(--popover)',
                    padding: '10px',
                    border: `1px solid var(--border)`,
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    color: 'var(--foreground)',
                  }}
                >
                  <p>{label}</p>
                  {payload.map((entry, index) => (
                    <div key={`item-${index}`}>
                      <span style={{ color: entry.color }}>{entry.name}:</span>{' '}
                      {entry.value} units
                    </div>
                  ))}
                  <div>
                    <strong>Total: {total} units</strong>
                  </div>
                </div>
              );
            }}
          />
          <Legend />
        </BarChart>
      </CardContent>
    </Card>
  );
};

export default LineChartCumulativeDistributions;
