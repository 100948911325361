import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import '../../styles/index.css';

const KpiDropdownTiny = ({
  kpiNames,
  selectedKpiId,
  onKpiSelect,
  label,
  selectorIndex,
}) => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <FormControl
      fullWidth
      sx={{
        minWidth: 120,
        marginBottom: 2,
        borderColor: isDarkMode ? 'var(--bg-secondary-dark)' : 'transparent',
        '& .MuiInputBase-root': {
          borderRadius: '8px',
        },
        '& .MuiSelect-icon': {
          right: 8,
          color: isDarkMode ? 'var(--border-light)' : 'inherit',
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          marginBottom: '4px',
          color: isDarkMode ? 'var(--border-light)' : 'textSecondary',
        }}
      >
        {label}
      </Typography>

      <Select
        value={selectedKpiId}
        onChange={event => onKpiSelect(selectorIndex, event.target.value)}
        sx={{
          '& .MuiSelect-select': {
            padding: '8px 14px',
            backgroundColor: isDarkMode
              ? 'var(--bg-secondary-dark)'
              : 'transparent',
            color: isDarkMode ? '#fff' : '#000',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            borderColor: isDarkMode ? '#ffffff' : 'var(--border-dark)',
          },
        }}
      >
        {Array.isArray(kpiNames) && kpiNames.length > 0 ? (
          kpiNames.map(kpi => (
            <MenuItem key={kpi.id} value={kpi.id}>
              {kpi.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No KPI data available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default KpiDropdownTiny;
