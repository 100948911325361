import React, { useState } from 'react';

const PanelCollapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div
        className="cursor-pointer bg-gray-200 dark:bg-gray-700 p-4 rounded-lg shadow-md"
        onClick={togglePanel}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      {isOpen && (
        <div className="mt-2 bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-inner">
          {children}
        </div>
      )}
    </div>
  );
};

export default PanelCollapsible;
