export const equityContributionIds = [4];
export const capitalReturnIds = [5, 6, 7];

export const project_types = ['Construction Land', 'Income Producing', 'Other'];
export const project_sub_types = [
  'Construction',
  'Land',
  'Income Producing',
  'Other',
];
export const construction_statuses = [
  'Pre-Development',
  'Servicing',
  'Active',
  'Not Active',
  'Complete',
];
export const user_roles = ['Admin', 'User'];
export const company_types = ['GP', 'LP'];

export const csv_data_example = [
  `
[
  {"KPI_ID": 4,
    "KPI_Name": "Equity Contribution",
    "Project_ID": 3,
    "2022-01-01": "633,466",
    "2022-02-01": "393,653",
    "2022-03-01": "152,881",
    ...
  },
  {"KPI_ID": 5,
    "KPI_Name": "Equity Distribution",
    "Project_ID": 3,
    "2022-01-01": "752,466",
    "2022-02-01": "458,623",
    "2022-03-01": "981,281",
    ...
  }
]
  `,
];
