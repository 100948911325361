// src/components/buttons/QuickFilterButtonGroup.js
import { Box, Button } from '@mui/material';
import React from 'react';

import '../../styles/index.css';
import CompaniesFilterDropdown from '../ui-elements/CompaniesFilterDropdown';
import KPIFilterDropdown from '../ui-elements/KpiFilterDropdown';

const DataGridQuickFilterButtonGroup = ({
  activeFilter,
  handleQuickFilter,
  uniqueKpis,
  selectedKPIs,
  onKPIFilterChange,
  companies,
  selectedCompanies,
  onCompanyFilterChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 2,
        mb: 2,
      }}
    >
      <Button
        className={`quick-filter-button ${activeFilter === 'equityContribution' ? 'active' : ''}`}
        variant="contained"
        onClick={() => handleQuickFilter('equityContribution')}
        sx={{
          '&:hover': {
            backgroundColor: 'var(--bg-button-accent-hover)',
          },
          '&.active': {
            backgroundColor: 'var(--bg-button-accent)',
          },
        }}
      >
        Equity Contribution
      </Button>

      <Button
        className={`quick-filter-button ${activeFilter === 'capitalReturns' ? 'active' : ''}`}
        variant="contained"
        onClick={() => handleQuickFilter('capitalReturns')}
        sx={{
          '&:hover': {
            backgroundColor: 'var(--bg-button-accent-hover)',
          },
          '&.active': {
            backgroundColor: 'var(--bg-button-accent)',
          },
        }}
      >
        Capital Returns
      </Button>
      <Button variant="contained" onClick={() => handleQuickFilter('reset')}>
        Reset
      </Button>
      <KPIFilterDropdown
        kpis={uniqueKpis}
        selectedKPIs={selectedKPIs}
        onFilterChange={onKPIFilterChange}
      />
      <CompaniesFilterDropdown
        companies={companies}
        selectedCompanies={selectedCompanies}
        onFilterChange={onCompanyFilterChange}
      />
    </Box>
  );
};

export default DataGridQuickFilterButtonGroup;
