import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import React, { useContext } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import '../../styles/index.css';

const DarkModeSwitchButton = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <Tooltip
      title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
      arrow
    >
      <Button
        variant="contained"
        onClick={toggleTheme}
        sx={{
          padding: '6px',
          width: '40px',
          height: '40px',
          backgroundColor: isDarkMode
            ? 'var(--bg-secondary-dark)'
            : 'var(--bg-secondary-light)',
          color: isDarkMode
            ? 'var(--bg-secondary-light)'
            : 'var(--bg-primary-dark)',
          '&:hover': {
            backgroundColor: isDarkMode
              ? 'var(--bg-secondary-light)'
              : 'var(--bg-secondary-dark)',
            color: isDarkMode
              ? 'var(--bg-primary-light)'
              : 'var(--bg-primary-dark)',
            '& .MuiSvgIcon-root': {
              color: isDarkMode
                ? 'var(--bg-primary-light)'
                : 'var(--bg-primary-light)',
            },
          },
          transition: 'background-color 0.3s, color 0.3s',
          minWidth: 'unset',
        }}
        aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
      >
        {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
      </Button>
    </Tooltip>
  );
};

export default DarkModeSwitchButton;
