import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

const ReportPreview = ({ data, selectedCompanies, selectedKPIs }) => {
  console.log('ReportPreview data:', data);
  console.log('selectedCompanies:', selectedCompanies);
  console.log('selectedKPIs:', selectedKPIs);

  if (!data) {
    return <Typography>No data available for preview</Typography>;
  }

  // Check if data has the expected structure
  if (!data.companies || !Array.isArray(data.companies)) {
    return <Typography>Invalid data structure for preview</Typography>;
  }

  const filteredData = data.companies.filter(company =>
    selectedCompanies.includes(company.id),
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Project</TableCell>
            <TableCell>KPI</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.flatMap(company =>
            (company.projects || []).flatMap(project =>
              (project.kpis || [])
                .filter(kpi => selectedKPIs.includes(kpi.id))
                .map((kpi, index) => (
                  <TableRow
                    key={`${company.id}-${project.id}-${kpi.id}-${index}`}
                  >
                    <TableCell>{company.name || 'N/A'}</TableCell>
                    <TableCell>{project.name || 'N/A'}</TableCell>
                    <TableCell>{kpi.name || 'N/A'}</TableCell>
                    <TableCell>${kpi.value?.toFixed(2) || 'N/A'}</TableCell>
                    <TableCell>
                      {kpi.date
                        ? new Date(kpi.date).toLocaleDateString()
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                )),
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportPreview;
