import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createAccount,
  deleteAccount,
  fetchAccounts,
  updateAccount,
} from '../../api/fetchers';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminAccountPanel = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [newAccount, setNewAccount] = useState({
    name: '',
    type: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    accountId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const accountsData = await fetchAccounts();
        setAccounts(accountsData);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setLoading(false);
      }
    };
    loadAccounts();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewAccount(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddAccount = async () => {
    try {
      const createdAccount = await createAccount(newAccount);
      setAccounts([...accounts, createdAccount]);
      resetForm();
      setToast({
        open: true,
        message: 'Account added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding account:', error);
      setToast({
        open: true,
        message: 'Error adding account. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleEditAccount = accountId => {
    const accountToEdit = accounts.find(account => account.id === accountId);
    setSelectedAccount(accountToEdit);
    setNewAccount({ ...accountToEdit });
  };

  const handleUpdateAccount = async () => {
    try {
      const updatedAccount = await updateAccount(
        selectedAccount.id,
        newAccount,
      );
      setAccounts(
        accounts.map(account =>
          account.id === selectedAccount.id ? updatedAccount : account,
        ),
      );
      resetForm();
      setToast({
        open: true,
        message: 'Account updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating account:', error);
      setToast({
        open: true,
        message: 'Error updating account. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  const resetForm = () => {
    setSelectedAccount(null);
    setNewAccount({
      name: '',
      type: '',
    });
  };

  const handleDeleteClick = accountId => {
    const accountToDelete = accounts.find(account => account.id === accountId);
    setConfirmDialog({
      open: true,
      title: 'Confirm Account Deletion',
      content: `Are you sure you want to delete the account "${accountToDelete.name}"? This action cannot be undone.`,
      accountId: accountId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteAccount(confirmDialog.accountId);
      setAccounts(
        accounts.filter(account => account.id !== confirmDialog.accountId),
      );
      setToast({
        open: true,
        message: 'Account deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting account:', error);
      setToast({
        open: true,
        message: 'Error deleting account. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Account Name', width: 200 },
    // { field: 'type', headerName: 'Account Type', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditAccount(params.row.id)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Accounts
      </Typography>
      <Box className="h-[600px] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={accounts}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Account Name"
              value={newAccount.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="type"
              label="Account Type"
              value={newAccount.type}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedAccount ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateAccount}
                >
                  Update Account
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAccount}
              >
                Add Account
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminAccountPanel;
