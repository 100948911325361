import axios from 'axios';

import { ACCESS_TOKEN } from '../api/login';

const API_URL =
  process.env.REACT_APP_API_URL || 'https://www.skalagroup.ca/api';

export const pythonAxios = axios.create({
  baseURL: API_URL,
});

pythonAxios.interceptors.request.use(
  async config => {
    const auth_token = sessionStorage.getItem(ACCESS_TOKEN);
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    } else {
      console.error('No auth token found');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export const fetchKPIData = async (
  projectId,
  kpiId,
  aggregation,
  formatted = false,
) => {
  try {
    const response = await pythonAxios.get(
      `/kpis/projects/${projectId}/kpis/${kpiId}`,
      {
        params: { aggregation, formatted },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error(
      'Error fetching KPI data:',
      error.response ? error.response.data : error.message,
    );
    throw error;
  }
};

export default pythonAxios;
