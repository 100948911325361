import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createCompany,
  deleteCompany,
  fetchCompanies,
  updateCompany,
} from '../../api/fetchers';
import { company_types } from '../../shared/constants';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminCompanyPanel = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [newCompany, setNewCompany] = useState({
    name: '',
    type: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    companyId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const companiesData = await fetchCompanies(0, 50); // Fetch 50 companies
        setCompanies(companiesData);
      } catch (error) {
        console.error('Error fetching companies:', error);
        setToast({
          open: true,
          message: 'Error loading companies. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    loadCompanies();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewCompany(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setNewCompany(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddCompany = async () => {
    try {
      const createdCompany = await createCompany(newCompany);
      setCompanies([...companies, createdCompany]);
      resetForm();
      setToast({
        open: true,
        message: 'Company added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding company:', error);
      setToast({
        open: true,
        message: 'Error adding company. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleEditCompany = companyId => {
    const companyToEdit = companies.find(company => company.id === companyId);
    setSelectedCompany(companyToEdit);
    setNewCompany({ ...companyToEdit });
  };

  const handleUpdateCompany = async () => {
    try {
      const updatedCompany = await updateCompany(
        selectedCompany.id,
        newCompany,
      );
      setCompanies(
        companies.map(company =>
          company.id === selectedCompany.id ? updatedCompany : company,
        ),
      );
      resetForm();
      setToast({
        open: true,
        message: 'Company updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating company:', error);
      setToast({
        open: true,
        message: 'Error updating company. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  const resetForm = () => {
    setSelectedCompany(null);
    setNewCompany({
      name: '',
      type: '',
    });
  };

  const handleDeleteClick = companyId => {
    const companyToDelete = companies.find(company => company.id === companyId);
    setConfirmDialog({
      open: true,
      title: 'Confirm Company Deletion',
      content: `Are you sure you want to delete the company "${companyToDelete.name}"? This action cannot be undone.`,
      companyId: companyId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteCompany(confirmDialog.companyId);
      setCompanies(
        companies.filter(company => company.id !== confirmDialog.companyId),
      );
      setToast({
        open: true,
        message: 'Company deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting company:', error);
      setToast({
        open: true,
        message: 'Error deleting company. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Company Name', width: 200 },
    { field: 'type', headerName: 'Company Type', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditCompany(params.row.id)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Companies
      </Typography>
      <Box className="h-[600px] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={companies}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Company Name"
              value={newCompany.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Company Type</InputLabel>
              <Select
                name="type"
                value={newCompany.type}
                onChange={handleSelectChange}
                label="Company Type"
              >
                {company_types.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedCompany ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateCompany}
                >
                  Update Company
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCompany}
              >
                Add Company
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminCompanyPanel;
