export const login_labels = {
  LOGIN_TITLE: 'Login',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'email',
  PASSWORD_LABEL: 'Password',
  PASSWORD_PLACEHOLDER: 'password',
  LOGIN_BUTTON: 'Login',
  NEW_ACCOUNT_QUESTION: "Don't have an account?",
  SIGNUP_BUTTON: 'Sign up',
  INVALID_CREDENTIALS_MSG: 'Invalid credentials',
};
