import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { fetchCurrentUserProfile, fetchUserAccounts } from '../api/fetchers';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';

// Import necessary fetchers

const Support = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState('');
  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const fetchCurrentUserAndAccount = useCallback(async () => {
    try {
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const userAccounts =
        (await fetchUserAccounts(currentUserProfile.id)) || [];
      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUserAndAccount();
  }, [fetchCurrentUserAndAccount]);

  const handleUserChange = useCallback(async user => {
    setSelectedUser(user);
    try {
      const userAccounts = (await fetchUserAccounts(user.id)) || [];
      const defaultAccount =
        userAccounts.find(acc => acc.name === `${user.username} Account`) ||
        userAccounts[0];
      setSelectedAccount(defaultAccount ? defaultAccount : '');
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <TopNavBar onUserChange={handleUserChange} />
      <Sidebar
        onThemeSwitch={handleThemeSwitch}
        currentTheme={theme}
        selectedUser={selectedUser}
        selectedAccount={selectedAccount}
      />
      <div className="main-content flex-1 flex flex-col p-4">
        <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
          <Typography
            variant="h4"
            className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Support
          </Typography>
          <Typography
            variant="h6"
            className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
          >
            Need help? Please use the form below to submit issues, feedback, and
            suggestions.
            {selectedUser
              ? ` Welcome, ${selectedUser.username}!`
              : ' Loading...'}
          </Typography>
        </Box>

        <Box className="bg-white dark:bg-card p-4 rounded shadow mb-3 flex-grow flex flex-col">
          <iframe
            className="airtable-embed"
            src="https://airtable.com/embed/appapvetJQfx9ytOE/pagmZNdZGjh7wER9R/form"
            style={{
              background: 'transparent',
              border: `1px solid ${currentTheme === 'dark' ? '#444' : '#ccc'}`,
              borderRadius: '4px',
              flexGrow: 1,
              height: '100%',
              width: '100%',
            }}
            title="Support Form"
          />
        </Box>
      </div>
    </div>
  );
};

export default Support;
