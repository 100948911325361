import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const DonutChartComponent = ({ data, title }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  // Define the chart colors using the CSS variables
  const chartColors = {
    chart1: isDarkMode ? 'var(--chart-1)' : 'var(--chart-1)',
    chart2: isDarkMode ? 'var(--chart-2)' : 'var(--chart-2)',
    chart3: isDarkMode ? 'var(--chart-3)' : 'var(--chart-3)',
    chart4: isDarkMode ? 'var(--chart-4)' : 'var(--chart-4)',
    chart5: isDarkMode ? 'var(--chart-5)' : 'var(--chart-5)',
  };

  return (
    <Card
      style={{
        backgroundColor: 'transparent',
        color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
      }}
      className="bg-transparent dark:bg-transparent"
    >
      <CardContent>
        <Typography
          variant="h6"
          align="left"
          style={{
            color: 'var(--foreground)',
          }}
        >
          {title}
        </Typography>
        <PieChart width={500} height={300}>
          <Pie
            data={data}
            dataKey="value"
            innerRadius={60}
            outerRadius={100}
            label={entry => `${entry.name}: ${entry.value}`}
            style={{ cursor: 'pointer' }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={chartColors[`chart${index + 1}`]}
              />
            ))}
          </Pie>
          <Tooltip
            content={({ payload }) => {
              if (!payload || payload.length === 0) return null;

              return (
                <div
                  style={{
                    backgroundColor: 'var(--popover)',
                    padding: '0px',
                    border: '1px solid var(--border)',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    color: 'var(--foreground)',
                  }}
                >
                  <p>{`${payload[0].name}: ${payload[0].value}`}</p>
                </div>
              );
            }}
          />
        </PieChart>
      </CardContent>
    </Card>
  );
};

export default DonutChartComponent;
