import React from 'react';
import { Launch as ButtonIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import '../../styles/index.css';

const TandemInvestorPortalButton = ({ label }) => {
  const handleButtonClick = () => {
    window.open('http://www.tandemgroup.com/investorarea/index.php?page=login');
  };

  return (
    <Button
    variant="contained"
    startIcon={<ButtonIcon />}
    onClick={handleButtonClick}
    sx={{
      padding: '6px 12px',
      height: '40px',
      backgroundColor: 'var(--bg-secondary-light)', 
          color: 'var(--bg-primary-dark)', 
          '&:hover': {
            backgroundColor: 'var(--accent-color)', 
          },
          transition: 'background-color 0.3s, color 0.3s',
    }}
    >
    {label}
    </Button>


  );
};

export default TandemInvestorPortalButton;

