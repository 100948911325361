import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import '../../styles/index.css';

const ReportCompaniesFilterDropdown = ({
  companies,
  selectedCompanies,
  onFilterChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = companyId => {
    onFilterChange(companyId);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: 'primary.main',
          color: 'var(--primary-foreground)',
          '&:hover': {
            backgroundColor: 'secondary.dark',
          },
        }}
      >
        Company Filters ({selectedCompanies.length})
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
          },
        }}
      >
        {companies.map(company => (
          <MenuItem key={company.id} onClick={() => handleToggle(company.id)}>
            <Checkbox
              checked={selectedCompanies.includes(company.id)}
              sx={{
                color: 'var(--foreground)',
                '&.Mui-checked': {
                  color: 'var(--accent-color)',
                },
              }}
            />
            <ListItemText
              primary={company.name}
              sx={{ color: 'var(--foreground)' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ReportCompaniesFilterDropdown;
