import React from 'react';

import '../../styles/index.css';

const KeyMetricsAggregateButtonGroup = ({ aggregate, setAggregate }) => {
  return (
    <div className="flex space-x-4">
      {/* <button
        className={`px-4 py-2 rounded ${aggregate === 'monthly' ? 'text-white' : 'bg-gray-200'}`}
        style={aggregate === 'monthly' ? { backgroundColor: 'var(--accent-color)' } : {}}
        onClick={() => setAggregate('monthly')}
      >
        M
      </button> */}
      <button
        className={`px-4 py-2 rounded ${aggregate === 'quarterly' ? 'text-white' : 'bg-gray-200'}`}
        style={
          aggregate === 'quarterly'
            ? { backgroundColor: 'var(--accent-color)' }
            : {}
        }
        onClick={() => setAggregate('quarterly')}
      >
        Q
      </button>
      <button
        className={`px-4 py-2 rounded ${aggregate === 'semi-annual' ? 'text-white' : 'bg-gray-200'}`}
        style={
          aggregate === 'semi-annual'
            ? { backgroundColor: 'var(--accent-color)' }
            : {}
        }
        onClick={() => setAggregate('semi-annual')}
      >
        SA
      </button>
      <button
        className={`px-4 py-2 rounded ${aggregate === 'annual' ? 'text-white' : 'bg-gray-200'}`}
        style={
          aggregate === 'annual'
            ? { backgroundColor: 'var(--accent-color)' }
            : {}
        }
        onClick={() => setAggregate('annual')}
      >
        A
      </button>
    </div>
  );
};

export default KeyMetricsAggregateButtonGroup;
