import React from 'react';
import { Loop as ButtonIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import '../../styles/index.css';

const ResetDefaultsButton = ({ onClick, label }) => (
  <Button
    variant="contained"
        onClick={onClick}
        startIcon={<ButtonIcon className='w-5 h-5'/>}
    sx={{
      height: '40px',
      backgroundColor: 'var(--bg-secondary-light)', 
      color: 'var(--bg-primary-dark)', 
      '&:hover': {
        backgroundColor: 'var(--accent-color)', 
      },
      transition: 'background-color 0.3s, color 0.3s', 
    }}
  >
    {label}
  </Button>
);

export default ResetDefaultsButton;
