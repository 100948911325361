import {
  Close as CloseIcon,
  ContentCopy,
  Description,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const CURRENT_VERSION = '0.9.5';
const APP_NAME = 'Tandem Investor Platform';
const COPYRIGHT_MSG = '© 2024 Skala & Tandem Group. All rights reserved.';
const RELEASE_NOTES = [
  {
    version: '0.9.5',
    description:
      'Beta release with additional bugfixes regarding display of dates on project dashboard charts',
    date: '2023-08-27',
  },
  {
    version: '0.9.1',
    description:
      'Beta release with critical bug fixes and performance optimizations',
    date: '2023-08-20',
  },
  {
    version: '0.9.0',
    description:
      'Initial beta deployment to live application and database with core functionality and basic UI',
    date: '2023-08-11',
  },
  {
    version: '0.8.5',
    description:
      'Alpha release with expanded portfolio datagrid, upload csv data functionality.',
    date: '2023-08-05',
  },
  {
    version: '0.5.0',
    description:
      'Alpha release introducing multi-user support and role-based access control',
    date: '2023-07-30',
  },
  {
    version: '0.2.0',
    description: 'Early frontend UI release with basic data viewing',
    date: '2023-07-15',
  },
  {
    version: '0.1.0',
    description: 'Initial development release with foundational architecture',
    date: '2023-06-30',
  },
];

const SystemInfo = ({ onCopy }) => {
  const [systemInfo, setSystemInfo] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const environment =
      process.env.NODE_ENV === 'production' ? 'Production' : 'Development';
    const browserInfo = `${navigator.userAgent}`;
    const info = `${environment} v${CURRENT_VERSION}\nBrowser: ${browserInfo}`;
    setSystemInfo(info);
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(systemInfo).then(() => {
      setCopied(true);
      onCopy();
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-700 p-2 rounded mb-4">
      <Typography className="text-sm text-gray-700 dark:text-gray-300 mr-2">
        {systemInfo.split('\n').map((line, index) => (
          <span key={index} className="block">
            {line}
          </span>
        ))}
      </Typography>
      <Tooltip title={copied ? 'Copied!' : 'Copy system info'} arrow>
        <IconButton
          onClick={handleCopy}
          size="small"
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const AboutMenuItem = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopy = () => {
    console.log('System info copied to clipboard');
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className="flex items-center space-x-2 p-2 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
      >
        <InfoIcon className="w-3 h-3" />
        <span>About</span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="about-modal-title"
        aria-describedby="about-modal-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-lg max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <Typography
              id="about-modal-title"
              variant="h6"
              component="h2"
              className="text-gray-900 dark:text-gray-100"
            >
              About {APP_NAME}
            </Typography>

            <IconButton
              onClick={handleClose}
              size="small"
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Divider className="mb-4" />

          <SystemInfo onCopy={handleCopy} />

          <Typography
            variant="body2"
            className="mb-4 text-gray-700 dark:text-gray-300"
          >
            {COPYRIGHT_MSG}
          </Typography>
          <Divider className="mb-4" />

          <Typography
            variant="subtitle1"
            className="mb-2 text-gray-900 dark:text-gray-100 font-semibold"
          >
            Release Notes:
          </Typography>
          <List className="max-h-96 overflow-y-auto mb-4 border border-gray-200 dark:border-gray-700 rounded">
            {RELEASE_NOTES.map((note, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider />}
                <ListItem className="py-2">
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className="text-gray-900 dark:text-gray-100"
                      >
                        {note.version}{' '}
                        <span className="text-gray-500 dark:text-gray-400 text-sm">
                          ({note.date})
                        </span>
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        className="text-gray-600 dark:text-gray-400 mt-1"
                      >
                        {note.description}
                      </Typography>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>

          <div className="flex justify-between items-center mt-2">
            <button
              onClick={handleClose}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 transition-colors"
            >
              Close
            </button>
            <a
              href="https://skalagroup.atlassian.net/wiki/x/AgA3AQ" // Replace with public documentation link (currently restricted to confluence members)
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
            >
              Documentation <Description className="ml-1 w-4 h-4" />
            </a>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AboutMenuItem;
