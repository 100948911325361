import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchUserCompanyStakes } from '../../api/fetchers';
import useKPIData from '../../hooks/useKPIData';
import useTheme from '../../hooks/useTheme';
import { projectsDataTableLabels } from '../../labels/dashboard_labels';
import '../../styles/index.css';

const DataTablePage = ({ projectId, kpiId, aggregation, title, userId }) => {
  const {
    data: kpiData,
    loading: kpiLoading,
    error,
  } = useKPIData(projectId, kpiId, aggregation);
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [viewShare, setViewShare] = useState(false);
  const [userCompanyStakes, setUserCompanyStakes] = useState([]);

  useEffect(() => {
    const fetchCompanyStakes = async () => {
      try {
        const response = await fetchUserCompanyStakes(userId);
        setUserCompanyStakes(response);
      } catch (error) {
        console.error('Error fetching company stakes:', error);
      }
    };
    fetchCompanyStakes();
  }, [userId]);

  if (error) {
    console.error('Error fetching KPI data:', error);
    return <div>Error fetching KPI data.</div>;
  }
  if (kpiLoading || !kpiData || kpiData.length === 0) {
    return <div>Loading...</div>;
  }

  // Map project ID to stake percentage
  const stakePercentages = (userCompanyStakes || []).reduce((acc, stake) => {
    if (stake.project_id === projectId) {
      acc[stake.project_id] = acc[stake.project_id] || 0;
      acc[stake.project_id] += stake.stake_percentage;
    }
    return acc;
  }, {});

  // Calculate respective shares if the switch is enabled
  const displayedData = kpiData.map(row => ({
    ...row,
    value:
      viewShare && stakePercentages[row.project_id] != null
        ? row.value * stakePercentages[row.project_id]
        : row.value,
  }));

  const columns = [
    {
      id: 'date',
      label: projectsDataTableLabels.TABLE_HEADER_DATE,
      format: value =>
        new Date(value).toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        }),
    },
    {
      id: 'project_id',
      label: projectsDataTableLabels.TABLE_HEADER_PROJECT_ID,
    },
    { id: 'kpi_name', label: projectsDataTableLabels.TABLE_HEADER_KPI_NAME },
    {
      id: 'value',
      label: projectsDataTableLabels.TABLE_HEADER_VALUE,
      format: value =>
        value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'CAD',
          minimumFractionDigits: 0,
        }),
    },
  ];

  return (
    <div className="mb-6">
      <Typography
        variant="h6"
        align="left"
        style={{
          color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        }}
      >
        {title}
      </Typography>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <FormControlLabel
          control={
            <Switch
              checked={viewShare}
              onChange={() => setViewShare(prev => !prev)}
              color="primary"
            />
          }
          label={projectsDataTableLabels.VIEW_COMPANY_STAKE}
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: isDarkMode ? 'var(--card)' : 'var(--card)',
          color: 'var(--foreground)',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  style={{ color: 'var(--foreground)' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map(row => (
              <TableRow key={row.id}>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    style={{ color: 'var(--foreground)' }}
                  >
                    {column.format
                      ? column.format(row[column.id])
                      : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTablePage;
