import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';

import {
  createUser,
  deleteUser,
  fetchUsers,
  updateUser,
} from '../../api/fetchers';
import { user_roles } from '../../shared/constants';
import ConfirmationDialog from '../ui-modals/ConfirmationDialog';
import Toast from '../ui-modals/ToastMessage';

const AdminUserPanel = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    role: '',
    password: '',
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    content: '',
    userId: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        const usersData = await fetchUsers();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };
    loadUsers();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewUser(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setNewUser(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddUser = async () => {
    try {
      const createdUser = await createUser(newUser);
      setUsers([...users, createdUser]);
      resetForm();
      setToast({
        open: true,
        message: 'User added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding user:', error);
      setToast({
        open: true,
        message: 'Error adding user. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleEditUser = userId => {
    const userToEdit = users.find(user => user.id === userId);
    setSelectedUser(userToEdit);
    setNewUser({ ...userToEdit, password: '' });
  };

  const handleUpdateUser = async () => {
    try {
      const dataToUpdate = { ...newUser };
      if (!dataToUpdate.password) {
        delete dataToUpdate.password;
      }
      const updatedUser = await updateUser(selectedUser.id, dataToUpdate);
      setUsers(
        users.map(user => (user.id === selectedUser.id ? updatedUser : user)),
      );
      resetForm();
      setToast({
        open: true,
        message: 'User updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error updating user:', error);
      setToast({
        open: true,
        message: 'Error updating user. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  const resetForm = () => {
    setSelectedUser(null);
    setNewUser({
      username: '',
      email: '',
      role: '',
      password: '',
    });
  };

  const handleDeleteClick = userId => {
    const userToDelete = users.find(user => user.id === userId);
    setConfirmDialog({
      open: true,
      title: 'Confirm User Deletion',
      content: `Are you sure you want to delete the user "${userToDelete.username}"? This action cannot be undone.`,
      userId: userId,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteUser(confirmDialog.userId);
      setUsers(users.filter(user => user.id !== confirmDialog.userId));
      setToast({
        open: true,
        message: 'User deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting user:', error);
      setToast({
        open: true,
        message: 'Error deleting user. Please try again.',
        severity: 'error',
      });
    } finally {
      setConfirmDialog({ ...confirmDialog, open: false });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'role', headerName: 'Role', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: params => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditUser(params.row.id)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Manage Users
      </Typography>
      <Box className="h-[600px] w-full mt-2" sx={{ flexGrow: 1, mb: 2 }}>
        <DataGridPremium
          rows={users}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              name="username"
              label="Username"
              value={newUser.username}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="email"
              label="Email"
              value={newUser.email}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                name="role"
                value={newUser.role}
                onChange={handleSelectChange}
                label="Role"
              >
                {user_roles.map(role => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="password"
              label="Password"
              type="password"
              value={newUser.password}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {selectedUser ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateUser}
                >
                  Update User
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={resetForm}
                >
                  Cancel Edit
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUser}
              >
                Add User
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Toast
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        content={confirmDialog.content}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  );
};

export default AdminUserPanel;
