import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from './components/ui-modals/Loading';
import { ThemeProvider } from './contexts/ThemeContext';
import Account from './pages/Account';
import Login from './pages/Login';
import PortfolioDashboard from './pages/PortfolioDashboard';
import ProjectDashboard from './pages/ProjectDashboard';
import Projects from './pages/Projects';
import ReportBuilder from './pages/ReportBuilder';
import Settings from './pages/Settings';
import Signup from './pages/Signup';
import Support from './pages/Support';
import UploadPage from './pages/Upload';
import ProtectedRoute from './routes/ProtectedRoute';
import './shared/mui-license';
import './styles/App.css';

export const app_paths = {
  PORTFOLIO_DASHBOARD_PATH: '/portfolio',
  PROJECTS_DASHBOARD_PATH: '/dashboard',
  PROJECTS_PATH: '/projects',
  UPLOAD_PATH: '/upload',
  REPORTS_PATH: '/reports',
  SETTINGS_PATH: '/settings',
  ACCOUNT_PATH: '/account',
  LOGIN_PATH: '/login',
  SUPPORT_PATH: '/support',
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to show the loading screen
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <ThemeProvider>
      {loading && <Loading message="Loading Account" />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path={app_paths.PORTFOLIO_DASHBOARD_PATH}
          element={
            <ProtectedRoute>
              <PortfolioDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.PROJECTS_DASHBOARD_PATH}
          element={
            <ProtectedRoute>
              <ProjectDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.PROJECTS_PATH}
          element={
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.UPLOAD_PATH}
          element={
            <ProtectedRoute>
              <UploadPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.REPORTS_PATH}
          element={
            <ProtectedRoute>
              <ReportBuilder />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.SETTINGS_PATH}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.ACCOUNT_PATH}
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path={app_paths.SUPPORT_PATH}
          element={
            <ProtectedRoute>
              <Support />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
