import Highcharts from 'highcharts';

const MixedChartOptions = (theme, aggregation) => {
  const isDark = theme === 'dark';

  // Define your chart colors using CSS variables from your index.css
  const chartColors = [
    getComputedStyle(document.documentElement)
      .getPropertyValue('--chart-1')
      .trim(),
    getComputedStyle(document.documentElement)
      .getPropertyValue('--chart-2')
      .trim(),
    getComputedStyle(document.documentElement)
      .getPropertyValue('--chart-3')
      .trim(),
    getComputedStyle(document.documentElement)
      .getPropertyValue('--chart-4')
      .trim(),
  ];

  return {
    chart: {
      backgroundColor: 'transparent', // Setting the background to transparent
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: 'thousands $CAD',
      align: 'left',
      style: {
        color: isDark ? '#FFFFFF' : '#b0b0b0', // Use dark mode colors if theme is dark
      },
    },
    xAxis: {
      crosshair: {
        color: 'rgba(204, 211, 229, 0.25)',
        width: 45,
        zIndex: 10,
      },
      type: 'datetime',
      title: {
        text: 'Date',
        style: {
          color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
        },
      },
      labels: {
        style: {
          color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
        },
      },
      lineColor: isDark ? '#444' : '#ccc', // Use dark mode colors if theme is dark
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
        style: {
          color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
        },
      },
      labels: {
        formatter: function () {
          return Highcharts.numberFormat(this.value / 1000, 0, ' ', ',');
        },
        style: {
          color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
        },
      },
      gridLineColor: isDark ? 'rgba(255, 255, 255, 0.1)' : '#e6e6e6', // Use dark mode colors if theme is dark
    },
    legend: {
      itemStyle: {
        color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const dateFormat = aggregation === 'annual' ? '%Y' : '%b, %Y';
        return (
          `<b>${Highcharts.dateFormat(dateFormat, this.x)}</b><br/>` +
          this.points
            .map(
              point =>
                `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: $${Highcharts.numberFormat(point.y, 0, '.', ',')}<br/>`,
            )
            .join('')
        );
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          color: isDark ? '#FFFFFF' : '#333', // Use dark mode colors if theme is dark
        },
      },
    },
    colors: chartColors,
    series: [], // Will be set dynamically
  };
};

export default MixedChartOptions;
