import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { fetchUserAccounts, fetchUserProjects } from '../../api/fetchers';
import ThemeContext from '../../contexts/ThemeContext';
import '../../styles/index.css';

const AccountProjectDropdown = ({
  userId,
  onProjectSelect,
  onAccountSelect,
  label,
  label2,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAccounts = await fetchUserAccounts(userId);
        setAccounts(userAccounts);

        if (userAccounts.length > 0) {
          const defaultAccount = userAccounts[0];
          setSelectedAccount(defaultAccount.id);
          onAccountSelect(defaultAccount.id);

          const userProjects = await fetchUserProjects(
            userId,
            defaultAccount.id,
          );
          setProjects(userProjects);

          if (userProjects.length > 0) {
            const defaultProject = userProjects[0];
            setSelectedProject(defaultProject.id);
            onProjectSelect(defaultProject.id);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const handleAccountChange = async event => {
    const accountId = event.target.value;
    setSelectedAccount(accountId);
    onAccountSelect(accountId);

    try {
      const userProjects = await fetchUserProjects(userId, accountId);
      setProjects(userProjects);

      if (userProjects.length > 0) {
        const defaultProject = userProjects[0];
        setSelectedProject(defaultProject.id);
        onProjectSelect(defaultProject.id);
      } else {
        setSelectedProject('');
        onProjectSelect(null);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setProjects([]);
      setSelectedProject('');
      onProjectSelect(null);
    }
  };

  const handleProjectChange = event => {
    const projectId = event.target.value;
    setSelectedProject(projectId);
    onProjectSelect(projectId);
  };

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            margin="normal"
            sx={{
              minWidth: 120,
              marginBottom: 2,
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
              '& .MuiSelect-icon': {
                right: 8,
                color: isDarkMode ? 'var(--border-light)' : 'inherit',
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginBottom: '4px',
                color: isDarkMode ? 'var(--border-light)' : 'textPrimary',
              }}
            >
              {label}
            </Typography>
            <Select
              value={selectedAccount}
              onChange={handleAccountChange}
              sx={{
                '& .MuiSelect-select': {
                  padding: '8px 14px',
                  backgroundColor: isDarkMode
                    ? 'var(--bg-secondary-dark)'
                    : 'var(--bg-secondary-light)',
                  color: isDarkMode ? '#fff' : '#000',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                  borderColor: isDarkMode ? '#ffffff' : 'var(--border-dark)',
                },
              }}
            >
              {accounts.map(account => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            margin="normal"
            sx={{
              minWidth: 120,
              marginBottom: 2,
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
              '& .MuiSelect-icon': {
                right: 8,
                color: isDarkMode ? 'var(--border-light)' : 'inherit',
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginBottom: '4px',
                color: isDarkMode ? 'var(--border-light)' : 'textPrimary',
              }}
            >
              {label2}
            </Typography>
            <Select
              value={selectedProject}
              onChange={handleProjectChange}
              sx={{
                '& .MuiSelect-select': {
                  padding: '8px 14px',
                  backgroundColor: isDarkMode
                    ? 'var(--bg-secondary-dark)'
                    : 'var(--bg-secondary-light)',
                  color: isDarkMode ? '#fff' : '#000',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                  borderColor: isDarkMode ? '#ffffff' : 'var(--border-dark)',
                },
              }}
            >
              {/* <MenuItem value="">All</MenuItem> */}
              {projects.map(project => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountProjectDropdown;
