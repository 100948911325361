import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { app_paths } from '../App';
import { loginUser } from '../api/login';
import SignupModal from '../components/ui-modals/SignupModal';
import { login_labels } from '../labels/login_labels';
import '../styles/index.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await loginUser(email, password);
      navigate(app_paths.PORTFOLIO_DASHBOARD_PATH);
    } catch (error) {
      setError(login_labels.INVALID_CREDENTIALS_MSG);
      console.error(error);
    }
  };

  const handleSignupOpen = () => setIsSignupOpen(true);
  const handleSignupClose = () => setIsSignupOpen(false);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-800">
      <div className="bg-white dark:bg-gray-900 p-10 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="mb-5 text-2xl font-bold text-center text-gray-800 dark:text-gray-200">
          {login_labels.LOGIN_TITLE}
        </h1>
        {error && <div className="text-red-600 text-center mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
            >
              {login_labels.EMAIL_LABEL}
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={login_labels.EMAIL_PLACEHOLDER}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 dark:text-gray-300 font-bold mb-2"
            >
              {login_labels.PASSWORD_LABEL}
            </label>
            <input
              type="password"
              id="password"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={login_labels.PASSWORD_PLACEHOLDER}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
          >
            {login_labels.LOGIN_BUTTON}
          </button>
        </form>
        <div className="text-center mt-4">
          {login_labels.NEW_ACCOUNT_QUESTION}{' '}
          <button
            onClick={handleSignupOpen}
            className="text-blue-500 hover:underline focus:outline-none"
          >
            {login_labels.SIGNUP_BUTTON}
          </button>
        </div>
      </div>
      <SignupModal isOpen={isSignupOpen} onClose={handleSignupClose} />
    </div>
  );
};

export default Login;
