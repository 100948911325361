import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchUserCompanyStakes } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import '../../styles/index.css';

const CompanyStakesCollapsibleTable = ({ userId, title }) => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  const transformData = data => {
    const companyMap = new Map();

    data.forEach(item => {
      if (!companyMap.has(item.company_id)) {
        companyMap.set(item.company_id, {
          id: `company-${item.company_id}`,
          company_name: item.company_name,
          numberOfProjects: 0,
          isCompany: true,
          children: [],
        });
      }
      const company = companyMap.get(item.company_id);
      company.numberOfProjects += 1;
      company.children.push({
        id: `company-${item.company_id}-project-${item.project_id}-inv-${item.investment_id}`,
        project_name: item.project_name,
        stake_percentage: item.stake_percentage,
        type: item.type,
        company_name: item.company_name,
        company_id: item.company_id,
        isCompany: false,
      });
    });

    const treeData = Array.from(companyMap.values());

    return treeData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserCompanyStakes(userId);
        const transformedData = transformData(data);
        setTreeData(transformedData);
      } catch (error) {
        console.error('Error fetching company stakes across Portfolio:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            style={{ color: 'var(--foreground)' }}
          >
            {row.company_name}
          </TableCell>
          <TableCell align="right" style={{ color: 'var(--foreground)' }}>
            {row.numberOfProjects}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  style={{ color: 'var(--foreground)' }}
                >
                  Projects
                </Typography>
                <Table size="small" aria-label="projects">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: 'var(--foreground)' }}>
                        Project Name
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: 'var(--foreground)' }}
                      >
                        Stake Percentage
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: 'var(--foreground)' }}
                      >
                        Investment Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.children.map(project => (
                      <TableRow key={project.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: 'var(--foreground)' }}
                        >
                          {project.project_name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: 'var(--foreground)' }}
                        >
                          {project.stake_percentage
                            ? `${(project.stake_percentage * 100).toFixed(2)}%`
                            : ''}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: 'var(--foreground)' }}
                        >
                          {project.type}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        align="left"
        style={{
          color: isDarkMode ? 'var(--foreground)' : 'var(--foreground)',
        }}
      >
        {title}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          margin: '0 auto',
          backgroundColor: isDarkMode ? 'var(--card)' : 'var(--card)',
          color: 'var(--foreground)',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${isDarkMode ? 'var(--border-dark)' : 'var(--border-light)'}`,
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ color: 'var(--foreground)' }}>
                Company
              </TableCell>
              <TableCell align="right" style={{ color: 'var(--foreground)' }}>
                Number of Projects
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treeData.map(row => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompanyStakesCollapsibleTable;
