import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, IconButton } from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { fetchUserPortfolioHierarchy } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import {
  capitalReturnIds,
  equityContributionIds,
} from '../../shared/constants';
import dataGridStyles from '../../styles/dataGridStyles';
import '../../styles/index.css';
import DataGridQuickFilterButtonGroup from '../buttons/DataGridQuickFilterButtonGroup';

const DEFAULT_VISIBLE_FIELDS = [
  'group',
  'companyName',
  'kpiName',
  'kpiValue',
  'date',
];

const PortfolioHierarchyGrid = ({ userId }) => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedKPIs, setSelectedKPIs] = useState(equityContributionIds);
  const [activeFilter, setActiveFilter] = useState('equityContribution');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [expansionDepth, setExpansionDepth] = useState(1);

  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const apiRef = useGridApiRef();

  // Function for expand/collapse toggle button inline column header
  function CustomGroupingHeader({ expansionDepth, setExpansionDepth }) {
    const toggleAllGroups = () => {
      setExpansionDepth(expansionDepth === -1 ? 0 : -1);
    };

    return (
      <IconButton onClick={toggleAllGroups}>
        {expansionDepth === -1 ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </IconButton>
    );
  }

  // Standalone buttons for expand/collapse all
  const handleExpandAll = () => {
    setExpansionDepth(-1);
  };

  const handleCollapseAll = () => {
    setExpansionDepth(0);
  };

  const handleRowClick = useCallback(
    params => {
      const isGroupRow = id => {
        return apiRef.current.getRowGroupChildren({ groupId: id }).length > 0;
      };

      if (isGroupRow(params.id)) {
        const isExpanded = apiRef.current.getRowNode(
          params.id,
        )?.childrenExpanded;
        apiRef.current.setRowChildrenExpansion(params.id, !isExpanded);
      }
    },
    [apiRef],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserPortfolioHierarchy(userId);
        setPortfolioData(data);
        setSelectedKPIs(prevKPIs => {
          if (prevKPIs.length === 0) {
            return Array.from(
              new Set(
                data.flatMap(company =>
                  company.projects.flatMap(project =>
                    project.kpis.map(kpi => kpi.id),
                  ),
                ),
              ),
            );
          }
          return prevKPIs;
        });
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const rows = useMemo(() => {
    return portfolioData.flatMap(company =>
      company.projects.flatMap(project =>
        project.kpis
          .filter(kpi => selectedKPIs.includes(kpi.id))
          .map((kpi, index) => ({
            id: `${company.id}-${project.id}-${kpi.id}-${index}`,
            company_id: company.id,
            companyName: company.name || 'N/A',
            companyType: company.type || 'N/A',
            projectName: project.name || 'N/A',
            stakePercentage: project.stake_percentage ?? 0,
            kpiId: kpi.id,
            kpiName: kpi.name || 'N/A',
            kpiValue: kpi.value ?? 0,
            date: kpi.date ? new Date(kpi.date) : null,
          })),
      ),
    );
  }, [portfolioData, selectedKPIs]);

  const columns = [
    { field: 'companyName', headerName: 'Company', width: 180 },
    { field: 'companyType', headerName: 'Company Type', width: 90 },
    { field: 'projectName', headerName: 'Project', width: 180 },
    {
      field: 'stakePercentage',
      headerName: 'Stake %',
      width: 120,
      type: 'number',
      renderCell: params => {
        if (params.value == null) {
          return '';
        }
        const percentage = params.value * 100;
        return `${percentage.toFixed(2)}%`;
      },
    },
    { field: 'kpiName', headerName: 'KPI', width: 150 },
    {
      field: 'kpiValue',
      headerName: 'Value',
      width: 150,
      type: 'number',
      renderCell: params => {
        if (params.value == null) return '';
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'CAD',
        }).format(params.value);
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      type: 'date',
      valueFormatter: params => {
        return params?.value ? new Date(params.value).toLocaleDateString() : '';
      },
      renderCell: params => {
        const date = new Date(params.value);
        return date instanceof Date && !isNaN(date)
          ? date.toLocaleDateString()
          : '';
      },
    },
  ];

  const uniqueKpis = useMemo(() => {
    const kpiMap = new Map();
    portfolioData.forEach(company =>
      company.projects.forEach(project =>
        project.kpis.forEach(kpi => {
          if (!kpiMap.has(kpi.id)) {
            kpiMap.set(kpi.id, { id: kpi.id, name: kpi.name });
          }
        }),
      ),
    );
    return Array.from(kpiMap.values()).sort((a, b) => a.id - b.id);
  }, [portfolioData]);

  const handleKPIFilterChange = newSelectedKPIs => {
    setSelectedKPIs(newSelectedKPIs);
  };

  const handleQuickFilter = filterType => {
    let newSelectedKPIs;
    switch (filterType) {
      case 'equityContribution':
        newSelectedKPIs = equityContributionIds;
        break;
      case 'capitalReturns':
        newSelectedKPIs = capitalReturnIds;
        break;
      case 'reset':
      default:
        newSelectedKPIs = Array.from(
          new Set(
            portfolioData.flatMap(company =>
              company.projects.flatMap(project =>
                project.kpis.map(kpi => kpi.id),
              ),
            ),
          ),
        );
        break;
    }
    setSelectedKPIs(newSelectedKPIs);
    setActiveFilter(filterType);
  };

  useEffect(() => {
    // Extract unique companies from rows
    const uniqueCompanies = Array.from(
      new Set(
        rows.map(row =>
          JSON.stringify({ id: row.company_id, name: row.companyName }),
        ),
      ),
    ).map(str => JSON.parse(str));
    setCompanies(uniqueCompanies);
    setSelectedCompanies(uniqueCompanies.map(company => company.id));
  }, [rows]);

  const handleCompanyFilterChange = companyId => {
    setSelectedCompanies(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const filteredRows = useMemo(() => {
    return rows.filter(
      row =>
        selectedKPIs.includes(row.kpiId) &&
        selectedCompanies.includes(row.company_id),
    );
  }, [rows, selectedKPIs, selectedCompanies]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Box>
          <Button
            onClick={handleExpandAll}
            variant="outlined"
            sx={{
              '&:hover': { backgroundColor: 'var(--bg-button-secondary)' },
              marginRight: 1,
            }}
          >
            Expand All
          </Button>
          <Button
            onClick={handleCollapseAll}
            variant="outlined"
            sx={{
              '&:hover': { backgroundColor: 'var(--bg-button-secondary)' },
            }}
          >
            Collapse All
          </Button>
        </Box>
        <DataGridQuickFilterButtonGroup
          activeFilter={activeFilter}
          handleQuickFilter={handleQuickFilter}
          uniqueKpis={uniqueKpis}
          selectedKPIs={selectedKPIs}
          onKPIFilterChange={handleKPIFilterChange}
          companies={companies}
          selectedCompanies={selectedCompanies}
          onCompanyFilterChange={handleCompanyFilterChange}
        />
      </Box>
      <Box
        className="h-[600px] w-full mt-2"
        sx={{
          ...Object.keys(dataGridStyles).reduce((acc, key) => {
            const styleKey = `& .MuiDataGrid-${key}`;
            acc[styleKey] = dataGridStyles[key];
            return acc;
          }, {}),
          '& .MuiDataGrid-row--aggregation, & .MuiDataGrid-row--lastVisible': {
            ...dataGridStyles.rowAggregation,
          },
        }}
      >
        <DataGridPremium
          rows={filteredRows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          apiRef={apiRef}
          onRowClick={handleRowClick}
          defaultGroupingExpansionDepth={expansionDepth}
          initialState={{
            aggregation: {
              model: {
                kpiValue: 'sum',
              },
            },
            rowGrouping: {
              model: ['companyName', 'projectName'],
            },
            columns: {
              columnVisibilityModel: Object.fromEntries(
                columns.map(col => [
                  col.field,
                  DEFAULT_VISIBLE_FIELDS.includes(col.field),
                ]),
              ),
            },
            density: 'compact',
          }}
          experimentalFeatures={{ aggregation: true }}
          groupingColDef={{
            headerName: 'Group',
            width: 350,
            renderHeader: () => (
              <CustomGroupingHeader
                expansionDepth={expansionDepth}
                setExpansionDepth={setExpansionDepth}
              />
            ),
            sortable: false,
          }}
        />
      </Box>
    </Box>
  );
};

export default PortfolioHierarchyGrid;
