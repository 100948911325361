import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, IconButton } from '@mui/material';
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { fetchUserFinancialModelData } from '../../api/fetchers';
import useTheme from '../../hooks/useTheme';
import {
  capitalReturnIds,
  equityContributionIds,
} from '../../shared/constants';
import dataGridStyles from '../../styles/dataGridStyles';
import DataGridQuickFilterButtonGroup from '../buttons/DataGridQuickFilterButtonGroup';

const DEFAULT_VISIBLE_FIELDS = [
  'company',
  'project',
  'kpiName',
  'kpiValue',
  'date',
];

const FinancialModelDataGrid = ({ userId }) => {
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedKPIs, setSelectedKPIs] = useState(equityContributionIds);
  const [activeFilter, setActiveFilter] = useState('equityContribution');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [expansionDepth, setExpansionDepth] = useState(1);

  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const apiRef = useGridApiRef();

  // Function for expand/collapse toggle button inline column header
  function CustomGroupingHeader({ expansionDepth, setExpansionDepth }) {
    const toggleAllGroups = () => {
      setExpansionDepth(expansionDepth === -1 ? 0 : -1);
    };

    return (
      <IconButton onClick={toggleAllGroups}>
        {expansionDepth === -1 ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </IconButton>
    );
  }

  // Standalone buttons for expand/collapse all
  const handleExpandAll = () => {
    setExpansionDepth(-1);
  };

  const handleCollapseAll = () => {
    setExpansionDepth(0);
  };

  const handleRowClick = useCallback(
    params => {
      const isGroupRow = id => {
        return apiRef.current.getRowGroupChildren({ groupId: id }).length > 0;
      };

      if (isGroupRow(params.id)) {
        const isExpanded = apiRef.current.getRowNode(
          params.id,
        )?.childrenExpanded;
        apiRef.current.setRowChildrenExpansion(params.id, !isExpanded);
      }
    },
    [apiRef],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserFinancialModelData(userId);
        setFinancialData(data);

        // Initialize selectedKPIs and companies
        setSelectedKPIs(prevKPIs => {
          if (prevKPIs.length === 0) {
            return Array.from(
              new Set(
                data.flatMap(company =>
                  company.projects.flatMap(project =>
                    project.kpis.map(kpi => kpi.id),
                  ),
                ),
              ),
            );
          }
          return prevKPIs;
        });
        setCompanies(
          data.map(company => ({ id: company.id, name: company.name })),
        );
        setSelectedCompanies(data.map(company => company.id));
      } catch (error) {
        console.error('Error fetching financial model data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const { rows, columns, uniqueKpis } = useMemo(() => {
    if (financialData.length === 0)
      return { rows: [], columns: [], uniqueKpis: [] };

    const years = new Set();
    const rows = [];
    const kpiMap = new Map();

    financialData.forEach(company => {
      company.projects.forEach(project => {
        project.kpis.forEach(kpi => {
          Object.keys(kpi.values).forEach(year => years.add(parseInt(year)));
          const row = {
            id: `${company.id}-${project.id}-${kpi.id}`,
            company: company.name,
            companyId: company.id,
            companyType: company.type,
            project: project.name,
            kpiName: kpi.name,
            kpiId: kpi.id,
            stakePercentage: project.stake_percentage,
            ...kpi.values,
          };
          rows.push(row);
          if (!kpiMap.has(kpi.id)) {
            kpiMap.set(kpi.id, { id: kpi.id, name: kpi.name });
          }
        });
      });
    });

    const baseColumns = [
      { field: 'company', headerName: 'Company', width: 180 },
      { field: 'companyType', headerName: 'Company Type', width: 130 },
      { field: 'project', headerName: 'Project', width: 180 },
      { field: 'kpiName', headerName: 'KPI', width: 150 },
      {
        field: 'stakePercentage',
        headerName: 'Stake %',
        width: 100,
        type: 'number',
        renderCell: params => {
          if (params.value == null) return '';
          const percentage = params.value * 100;
          return `${percentage.toFixed(2)}%`;
        },
      },
    ];

    const yearColumns = Array.from(years)
      .sort()
      .map(year => ({
        field: year.toString(),
        headerName: year.toString(),
        type: 'number',
        width: 120,
        renderCell: params => {
          if (params.value == null) return '';
          return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            currency: 'CAD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(params.value);
        },
      }));

    return {
      rows,
      columns: [...baseColumns, ...yearColumns],
      uniqueKpis: Array.from(kpiMap.values()),
    };
  }, [financialData]);

  const filteredRows = useMemo(() => {
    return rows.filter(
      row =>
        selectedKPIs.includes(row.kpiId) &&
        selectedCompanies.includes(row.companyId),
    );
  }, [rows, selectedKPIs, selectedCompanies]);

  const handleKPIFilterChange = newSelectedKPIs => {
    setSelectedKPIs(newSelectedKPIs);
  };

  const handleCompanyFilterChange = companyId => {
    setSelectedCompanies(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const handleQuickFilter = filterType => {
    let newSelectedKPIs;
    switch (filterType) {
      case 'equityContribution':
        newSelectedKPIs = equityContributionIds;
        break;
      case 'capitalReturns':
        newSelectedKPIs = capitalReturnIds;
        break;
      case 'reset':
      default:
        newSelectedKPIs = Array.from(
          new Set(
            financialData.flatMap(company =>
              company.projects.flatMap(project =>
                project.kpis.map(kpi => kpi.id),
              ),
            ),
          ),
        );
        break;
    }
    setSelectedKPIs(newSelectedKPIs);
    setActiveFilter(filterType);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Box>
          <Button
            onClick={handleExpandAll}
            variant="outlined"
            sx={{ marginRight: 1 }}
          >
            Expand All
          </Button>
          <Button onClick={handleCollapseAll} variant="outlined">
            Collapse All
          </Button>
        </Box>
        <DataGridQuickFilterButtonGroup
          activeFilter={activeFilter}
          handleQuickFilter={handleQuickFilter}
          uniqueKpis={uniqueKpis}
          selectedKPIs={selectedKPIs}
          onKPIFilterChange={handleKPIFilterChange}
          companies={companies}
          selectedCompanies={selectedCompanies}
          onCompanyFilterChange={handleCompanyFilterChange}
        />
      </Box>
      <Box
        className="h-[600px] w-full mt-2"
        sx={{
          ...Object.keys(dataGridStyles).reduce((acc, key) => {
            const styleKey = `& .MuiDataGrid-${key}`;
            acc[styleKey] = dataGridStyles[key];
            return acc;
          }, {}),
          '& .MuiDataGrid-row--aggregation, & .MuiDataGrid-row--lastVisible': {
            ...dataGridStyles.rowAggregation,
          },
        }}
      >
        <DataGridPremium
          rows={filteredRows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          apiRef={apiRef}
          onRowClick={handleRowClick}
          defaultGroupingExpansionDepth={expansionDepth}
          initialState={{
            aggregation: {
              model: {
                ...Object.fromEntries(
                  columns
                    .filter(col => col.type === 'number')
                    .map(col => [col.field, 'sum']),
                ),
              },
            },
            rowGrouping: {
              model: ['company', 'project', 'kpiName'],
            },
            columns: {
              columnVisibilityModel: {
                companyType: false,
                stakePercentage: false,
              },
            },
          }}
          experimentalFeatures={{ aggregation: true }}
          groupingColDef={{
            headerName: 'Group',
            width: 350,
            renderHeader: () => (
              <CustomGroupingHeader
                expansionDepth={expansionDepth}
                setExpansionDepth={setExpansionDepth}
              />
            ),
            sortable: false,
          }}
        />
      </Box>
    </Box>
  );
};

export default FinancialModelDataGrid;
