import React, { useContext, useEffect } from 'react';

import ThemeContext from '../../contexts/ThemeContext';
import DarkModeSwitchButton from '../buttons/DarkModeSwitchButton';

const DarkModeSwitch = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    if (savedTheme !== theme) {
      toggleTheme();
    }
  }, [theme, toggleTheme]);

  return <DarkModeSwitchButton />;
};

export default DarkModeSwitch;
