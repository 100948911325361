import React from 'react';

const Loading = ({ message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 bg-opacity-80 z-50">
      <div className="bg-white p-8 rounded shadow-lg text-center">
        <div className="border-4 border-gray-200 border-t-blue-500 rounded-full w-10 h-10 animate-spin mx-auto mb-4"></div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Loading;
