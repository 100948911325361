import { GppBad, VerifiedUser } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import useTheme from '../../hooks/useTheme';
import pythonAxios from '../../services/axiosConfigPython';
import '../../styles/index.css';

const CustomBackdrop = React.forwardRef(function CustomBackdrop(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className="fixed inset-0 bg-black bg-opacity-80 z-50"
    />
  );
});

const AuthStatus = () => {
  const [status, setStatus] = useState('checking');
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  const checkAuthStatus = async () => {
    try {
      await pythonAxios.get('/auth/check-auth');
      setStatus('authenticated');
      setIsAuthenticated(true);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setStatus('unauthenticated');
        setIsAuthenticated(false);
        setIsModalOpen(true);
      }
    }
  };

  useEffect(() => {
    checkAuthStatus();
    const interval = setInterval(checkAuthStatus, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <>
      <div
        className={`fixed bottom-4 right-4 flex items-center p-2 rounded-md z-40 transition-all duration-300 ${
          isAuthenticated ? 'bg-green-500' : 'bg-red-500 animate-pulse'
        }`}
        data-tooltip-id="authStatusTooltip"
        data-tooltip-content={
          isAuthenticated ? 'User authenticated' : 'User not authenticated'
        }
      >
        {status === 'checking' ? (
          <div className="flex items-center">
            <div className="animate-spin h-5 w-5 mr-2 border-t-2 border-b-2 border-white rounded-full"></div>
            <span className="text-white">Checking...</span>
          </div>
        ) : (
          <div className="flex items-center">
            {isAuthenticated ? (
              <VerifiedUser className="w-6 h-6 text-white mr-2" />
            ) : (
              <GppBad className="w-6 h-6 text-white mr-2" />
            )}
            <span className="text-white">
              {isAuthenticated ? 'Logged in' : 'Authentication expired'}
            </span>
          </div>
        )}
      </div>
      <Tooltip id="authStatusTooltip" place="top" className="tooltip-fixed" />
      <Modal
        open={isModalOpen}
        slots={{
          backdrop: CustomBackdrop,
        }}
        disableEscapeKeyDown
        disableAutoFocus
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'var(--bg-secondary)',
            color: 'var(--foreground)',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            zIndex: 60,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontWeight: 'bold', color: 'var(--foreground)' }}
          >
            Session Expired
          </Typography>
          <Typography sx={{ mb: 3, color: 'var(--foreground)' }}>
            Your session has expired. Please log in again to continue.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleLogout}
              variant="contained"
              sx={{
                bgcolor: 'var(--bg-button-default)',
                '&:hover': {
                  bgcolor: 'var(--bg-button-secondary)',
                },
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AuthStatus;
