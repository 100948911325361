import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useCallback, useEffect, useState } from 'react';

import {
  fetchAllKPIs,
  fetchCurrentUserProfile,
  fetchReportData,
  fetchUserAccounts,
  fetchUserCompanies,
  fetchUserProjects,
} from '../api/fetchers';
import AuthStatus from '../components/auth/AuthStatus';
import GeneratePDFButton from '../components/buttons/GeneratePDFButton';
import KPIFilterDropdown from '../components/ui-elements/KpiFilterDropdown';
import ReportCompaniesFilterDropdown from '../components/ui-elements/ReportCompaniesFilterDropdown';
import ReportPreview from '../components/ui-elements/ReportPreview';
import Sidebar from '../components/ui-elements/Sidebar';
import TopNavBar from '../components/ui-elements/TopNavBar';
import useTheme from '../hooks/useTheme';
import '../styles/index.css';

const DEFAULT_CUSTOMIZATION_OPTIONS = {
  companyIds: [],
  projectIds: [],
  startDate: new Date(new Date().getFullYear() - 2, 0, 1),
  endDate: new Date(new Date().getFullYear() + 2, 11, 31),
  kpiIds: [4, 5, 6],
};

const ReportBuilder = ({ userId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [reportData, setReportData] = useState(null);
  const [customizationOptions, setCustomizationOptions] = useState(
    DEFAULT_CUSTOMIZATION_OPTIONS,
  );
  const [activeTab, setActiveTab] = useState(0);
  const [kpis, setKpis] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme, handleThemeSwitch } = useTheme();
  const currentTheme = theme === 'dark' ? 'dark' : 'light';

  const resetToDefaults = useCallback(() => {
    setSelectedCompanyIds([]);
    setCustomizationOptions(DEFAULT_CUSTOMIZATION_OPTIONS);
  }, []);

  const fetchData = useCallback(
    async (userId, options) => {
      if (!userId) return;
      try {
        const formattedOptions = {
          companyIds: selectedCompanyIds,
          kpiIds: customizationOptions.kpiIds,
          startDate: customizationOptions.startDate
            ? customizationOptions.startDate.toISOString().split('T')[0]
            : null,
          endDate: customizationOptions.endDate
            ? customizationOptions.endDate.toISOString().split('T')[0]
            : null,
          projectIds: [],
        };
        // console.log('Fetching report data with options:', formattedOptions);
        const data = await fetchReportData(userId, formattedOptions);
        // console.log('Received report data:', data);
        setReportData(data);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    },
    [selectedCompanyIds, customizationOptions],
  );

  useEffect(() => {
    if (
      selectedUser &&
      selectedCompanyIds.length > 0 &&
      customizationOptions.kpiIds.length > 0
    ) {
      const updatedOptions = {
        ...customizationOptions,
        companyIds: selectedCompanyIds,
      };
      //   console.log('Fetching data with options:', updatedOptions);
      fetchData(selectedUser.id, updatedOptions);
    }
  }, [selectedUser, selectedCompanyIds, customizationOptions, fetchData]);

  const fetchInitialData = useCallback(async () => {
    try {
      setLoading(true);
      const currentUserProfile = await fetchCurrentUserProfile();
      setSelectedUser(currentUserProfile);

      const [userCompanies, userProjects, userAccounts, allKPIs] =
        await Promise.all([
          fetchUserCompanies(currentUserProfile.id),
          fetchUserProjects(currentUserProfile.id),
          fetchUserAccounts(currentUserProfile.id),
          fetchAllKPIs(),
        ]);

      setCompanies(userCompanies);
      setProjects(userProjects);
      setAccounts(userAccounts);
      setFilteredAccounts(userAccounts);
      setKpis(allKPIs);

      const defaultAccount =
        userAccounts.find(
          acc => acc.name === `${currentUserProfile.username} Account`,
        ) || userAccounts[0];
      setSelectedAccount(defaultAccount || '');
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const handleUserChange = useCallback(
    async user => {
      setSelectedUser(user);
      setLoading(true);
      resetToDefaults();
      try {
        const [userCompanies, userProjects, userAccounts] = await Promise.all([
          fetchUserCompanies(user.id),
          fetchUserProjects(user.id),
          fetchUserAccounts(user.id),
        ]);

        setCompanies(userCompanies);
        setProjects(userProjects);
        setAccounts(userAccounts);
        setFilteredAccounts(userAccounts);

        const defaultAccount =
          userAccounts.find(acc => acc.name === `${user.username} Account`) ||
          userAccounts[0];
        setSelectedAccount(defaultAccount ? defaultAccount : '');

        setCustomizationOptions(prevOptions => ({
          ...prevOptions,
          startDate: new Date(new Date().getFullYear() - 2, 0, 1),
          endDate: new Date(new Date().getFullYear() + 2, 11, 31),
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    },
    [resetToDefaults],
  );

  const handleCompanyFilterChange = companyId => {
    setSelectedCompanyIds(prevSelected => {
      if (prevSelected.includes(companyId)) {
        return prevSelected.filter(id => id !== companyId);
      } else {
        return [...prevSelected, companyId];
      }
    });
  };

  const handleCustomizationChange = newOptions => {
    setCustomizationOptions(prevOptions => {
      const updatedOptions = {
        ...prevOptions,
        ...newOptions,
        companyIds: selectedCompanyIds,
      };
      return updatedOptions;
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabStyle = {
    '&.Mui-selected:hover': {
      color: '#fff',
    },
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
        <TopNavBar onUserChange={handleUserChange} />
        <Sidebar
          onThemeSwitch={handleThemeSwitch}
          currentTheme={theme}
          selectedUser={selectedUser}
          selectedAccount={selectedAccount}
        />
        <div className="main-content flex-1 flex flex-col p-4">
          <Box className="card bg-white dark:bg-card p-4 rounded shadow mb-4">
            <Typography
              variant="h4"
              className={`font-bold mb-2 ${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
            >
              Report Builder
            </Typography>
            <Typography
              variant="h6"
              className={`${currentTheme === 'dark' ? 'text-white' : 'text-black'}`}
            >
              Customize and generate your reports here.
              {selectedUser
                ? ` Welcome, ${selectedUser.username}!`
                : ' Loading...'}
            </Typography>
          </Box>

          <Box className="bg-white dark:bg-card p-4 rounded shadow" mb={3}>
            <Alert severity="info" className="mb-4">
              <AlertTitle>
                Select Companies, project metrics, and date range. Use the
                Preview pane to review data before generating pdf.
              </AlertTitle>
            </Alert>

            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="Customize Report" sx={tabStyle} />
              <Tab label="Preview" sx={tabStyle} />
            </Tabs>
            {activeTab === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  maxWidth: '300px',
                  gap: '16px',
                }}
              >
                <ReportCompaniesFilterDropdown
                  companies={companies}
                  selectedCompanies={selectedCompanyIds}
                  onFilterChange={handleCompanyFilterChange}
                  sx={{ width: '100%' }}
                />
                <KPIFilterDropdown
                  kpis={kpis}
                  selectedKPIs={customizationOptions.kpiIds}
                  onFilterChange={selectedKPIs =>
                    handleCustomizationChange({
                      ...customizationOptions,
                      kpiIds: selectedKPIs,
                    })
                  }
                  sx={{ width: '100%' }}
                />
                <DatePicker
                  label="Start Year"
                  views={['year']}
                  value={customizationOptions.startDate}
                  onChange={date => {
                    if (date) {
                      const startOfYear = new Date(date.getFullYear(), 0, 1);
                      handleCustomizationChange({
                        ...customizationOptions,
                        startDate: startOfYear,
                      });
                    } else {
                      handleCustomizationChange({
                        ...customizationOptions,
                        startDate: null,
                      });
                    }
                  }}
                  renderInput={params => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="End Year"
                  views={['year']}
                  value={customizationOptions.endDate}
                  onChange={date => {
                    if (date) {
                      const endOfYear = new Date(date.getFullYear(), 11, 31);
                      handleCustomizationChange({
                        ...customizationOptions,
                        endDate: endOfYear,
                      });
                    } else {
                      handleCustomizationChange({
                        ...customizationOptions,
                        endDate: null,
                      });
                    }
                  }}
                  renderInput={params => <TextField {...params} fullWidth />}
                />
                <GeneratePDFButton
                  userId={selectedUser?.id}
                  options={{
                    ...customizationOptions,
                    companyIds: selectedCompanyIds,
                  }}
                  reportData={reportData}
                  disabled={!reportData || Object.keys(reportData).length === 0}
                />
              </Box>
            )}
            {activeTab === 1 && (
              <>
                {reportData ? (
                  <ReportPreview
                    data={reportData}
                    selectedCompanies={selectedCompanyIds}
                    selectedKPIs={customizationOptions.kpiIds}
                  />
                ) : (
                  <Typography>
                    {customizationOptions.companyIds.length > 0 &&
                    customizationOptions.kpiIds.length > 0
                      ? 'Loading report data...'
                      : 'No report data available. Please select companies and KPIs.'}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </div>
        <AuthStatus />
      </div>
    </LocalizationProvider>
  );
};

export default ReportBuilder;
