import {
  AccountTree,
  AdminPanelSettings,
  Assignment,
  CreateNewFolder,
  Dashboard,
  Domain,
  ExpandLess,
  ExpandMore,
  Feedback,
  Person,
  PostAdd,
  Settings,
  SettingsBrightness,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { app_paths } from '../../App';
import { menu_labels } from '../../labels/menu_labels';
import AboutMenuItem from '../ui-modals/AboutModal';
import DarkModeSwitch from '../ui-modals/DarkModeSwitch';

const Sidebar = ({
  onThemeSwitch,
  currentTheme,
  selectedUser,
  selectedAccount,
}) => {
  const location = useLocation();
  const [adminOpen, setAdminOpen] = useState(true);
  const [accountExpanded, setAccountExpanded] = useState(true);

  const isActive = path => location.pathname === path;

  const MenuItem = ({ to, icon, label, isChild = false }) => (
    <Link
      to={to}
      className={`flex items-center space-x-2 p-2 rounded ${
        isActive(to)
          ? 'bg-blue-500 text-white'
          : 'hover:bg-gray-200 dark:hover:bg-gray-700'
      } ${isChild ? 'ml-4 text-gray-600 dark:text-gray-400' : ''}`}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );

  return (
    <div className="h-screen fixed top-0 left-0 w-64 bg-white dark:bg-gray-900 border-r border-gray-300 dark:border-gray-700 text-gray-800 dark:text-white flex flex-col rounded-tr-lg rounded-br-lg overflow-hidden">
      <div className="p-4 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Domain className="w-6 h-6" />
          <span className="font-bold truncate">
            {selectedAccount ? selectedAccount.name : 'Loading...'}
          </span>
        </div>
        <button onClick={() => setAccountExpanded(!accountExpanded)}>
          {accountExpanded ? <ExpandLess /> : <ExpandMore />}
        </button>
      </div>

      <nav className="flex-grow overflow-y-auto">
        <div className="p-2">
          <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-2 ml-2">
            Client Platform
          </h3>
          <ul className="space-y-1">
            <MenuItem
              to={app_paths.PORTFOLIO_DASHBOARD_PATH}
              icon={<Dashboard className="w-3 h-3" />}
              label={menu_labels.PORTFOLIO_DASHBOARD_TITLE}
            />
            <MenuItem
              to={app_paths.PROJECTS_DASHBOARD_PATH}
              icon={<AccountTree className="w-3 h-3" />}
              label={menu_labels.PROJECTS_DASHBOARD_TITLE}
            />
            <MenuItem
              to={app_paths.REPORTS_PATH}
              icon={<PostAdd className="w-3 h-3" />}
              label={menu_labels.REPORTS_PAGE_TITLE}
            />
          </ul>
        </div>

        <div className="p-2 border-t border-gray-300 dark:border-gray-700">
          <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-2 ml-2">
            Admin Tools
          </h3>
          <button
            onClick={() => setAdminOpen(!adminOpen)}
            className="flex items-center justify-between w-full p-2 rounded hover:bg-gray-200 dark:hover:bg-gray-700"
          >
            <div className="flex items-center space-x-2">
              <AdminPanelSettings className="w-3 h-3" />
              <span>Admin</span>
            </div>
            {adminOpen ? <ExpandLess /> : <ExpandMore />}
          </button>
          {adminOpen && (
            <ul className="mt-1 space-y-1">
              <MenuItem
                to={app_paths.PROJECTS_PATH}
                icon={<Assignment className="w-3 h-3" />}
                label={menu_labels.PROJECTS_PAGE_TITLE}
                isChild
              />
              <MenuItem
                to={app_paths.UPLOAD_PATH}
                icon={<CreateNewFolder className="w-3 h-3" />}
                label={menu_labels.UPLOAD_PAGE_TITLE}
                isChild
              />
              <MenuItem
                to={app_paths.SETTINGS_PATH}
                icon={<Settings className="w-3 h-3" />}
                label={menu_labels.SETTINGS_PAGE_TITLE}
                isChild
              />
            </ul>
          )}
        </div>
      </nav>

      <div className="p-2 border-gray-300 dark:border-gray-700">
        <h3 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-0 ml-2">
          Help
        </h3>
        <ul className="space-y-1">
          <MenuItem
            to={app_paths.SUPPORT_PATH}
            icon={<Feedback className="w-3 h-3" />}
            label={menu_labels.SUPPORT_PAGE_TITLE}
          />
          <AboutMenuItem />
          <li className="flex items-center justify-between">
            <div className="flex items-center space-x-2 p-2 rounded cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700">
              <SettingsBrightness className="w-3 h-3" />
              <span>View Mode</span>
            </div>
            <DarkModeSwitch size="small" />
          </li>
        </ul>
      </div>

      {/* Account Section */}
      <div className="p-4 border-t border-gray-300 dark:border-gray-700 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Person className="w-6 h-6" />
          <div>
            <p className="text-sm font-bold">
              {selectedUser ? selectedUser.username : 'Loading...'}
            </p>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {selectedAccount ? selectedAccount.name : 'Loading...'}
            </span>
          </div>
        </div>
        <button onClick={() => setAccountExpanded(!accountExpanded)}>
          {accountExpanded ? <ExpandLess /> : <ExpandMore />}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
